import React, { useContext, useEffect, useRef } from 'react'
import { formatDateForQuotes } from '../../../../utils/formatDate'
import PropTypes from 'prop-types'
import { MeetingsContext } from '../../../../context/MeetingContext'

export function Quote ({ quote, index, setCurrentQuote }) {
  const quoteRef = useRef(null)
  const { meeting, meetingOverview, scrollToQuote, scrollQuotesContainer, scrollTopContainer, participants } = useContext(MeetingsContext)

  useEffect(() => {
    if (!scrollQuotesContainer || !quoteRef || !scrollToQuote) return
    if (scrollToQuote === quote.id) {
      let element = quoteRef.current
      let leftOffset = 0
      while (element) {
        leftOffset += element.offsetLeft
        element = element.offsetParent
      }

      // Scroll to the element
      scrollQuotesContainer.scrollTo({
        top: 0,
        left: leftOffset - (window.innerWidth * 0.32),
        behavior: 'smooth'
      })

      scrollTopContainer.scrollTo({
        top: quoteRef.current.offsetTop - 100,
        behavior: 'smooth'
      })
    }
  }, [scrollToQuote])

  return (
      <button key={quote.id} ref={quoteRef} className='min-w-[290px] h-max px-[20px] py-[15px] flex flex-col text-start gap-[12px] border border-[#e4e4e4]' style={{ borderRadius: 4 }} onClick={() => setCurrentQuote(index)}>
        <div className='flex w-full items-center justify-between pb-[12px] border-b border-b-[#e4e4e4]'>
          <div>
            <p className="text-[#3f424a] text-xs font-extrabold font-['Manrope'] uppercase leading-none tracking-wide">{quote.participant_id && participants[quote.participant_id]?.name ? participants[quote.participant_id].name : quote.who_said_it}</p>
            {meetingOverview.background?.job && <p className="mt-1 text-[#62708d] text-[13px] font-medium font-['Manrope'] leading-[18.20px]">{meetingOverview.background?.job}</p>}
          </div>
          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4074_2135)">
            <path d="M14.1693 15.3418H8.5026C8.12688 15.3418 7.76655 15.1969 7.50087 14.9391C7.23519 14.6812 7.08594 14.3315 7.08594 13.9668V9.8418C7.08594 9.47712 7.23519 9.12739 7.50087 8.86953C7.76655 8.61166 8.12688 8.4668 8.5026 8.4668H12.7526C13.1283 8.4668 13.4887 8.61166 13.7543 8.86953C14.02 9.12739 14.1693 9.47712 14.1693 9.8418V18.0918C14.1693 21.7589 12.2809 24.0497 8.5026 24.9668" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M26.9193 15.3418H21.2526C20.8769 15.3418 20.5165 15.1969 20.2509 14.9391C19.9852 14.6812 19.8359 14.3315 19.8359 13.9668V9.8418C19.8359 9.47712 19.9852 9.12739 20.2509 8.86953C20.5165 8.61166 20.8769 8.4668 21.2526 8.4668H25.5026C25.8783 8.4668 26.2387 8.61166 26.5043 8.86953C26.77 9.12739 26.9193 9.47712 26.9193 9.8418V18.0918C26.9193 21.7589 25.0309 24.0497 21.2526 24.9668" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_4074_2135">
            <rect width="34" height="33" fill="white" transform="translate(0 0.216797)"/>
            </clipPath>
            </defs>
          </svg>
        </div>

        <div className='pb-[12px] border-b border-b-[#e4e4e4]'>
          <p className="text-[#3f424a] text-xs font-semibold font-['Manrope'] leading-[15px]">“{quote.original_transcript_segment?.length > 950 ? quote.original_transcript_segment.slice(0, 950) + '...' : quote.original_transcript_segment}“</p>
        </div>

        <div className='flex items-center'>
          <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4179_2249)">
            <path d="M10.1821 9.62482V19.8066C10.1821 19.9199 10.2123 20.0311 10.2696 20.1287C10.3268 20.2264 10.4091 20.307 10.508 20.3623C10.6068 20.4175 10.7186 20.4454 10.8318 20.443C10.945 20.4407 11.0555 20.4082 11.1519 20.3488L19.4247 15.2579C19.5173 15.201 19.5938 15.1213 19.6469 15.0263C19.7 14.9314 19.7279 14.8245 19.7279 14.7157C19.7279 14.607 19.7 14.5 19.6469 14.4051C19.5938 14.3102 19.5173 14.2305 19.4247 14.1736L11.1519 9.08264C11.0555 9.02331 10.945 8.99078 10.8318 8.98842C10.7186 8.98606 10.6068 9.01394 10.508 9.06919C10.4091 9.12444 10.3268 9.20506 10.2696 9.30274C10.2123 9.40041 10.1821 9.5116 10.1821 9.62482Z" fill="#FF4A8E"/>
            </g>
            <circle cx="14" cy="14.7168" r="13" stroke="#62718D" strokeWidth="2"/>
            <defs>
            <clipPath id="clip0_4179_2249">
            <rect width="15.2727" height="15.2727" fill="white" transform="translate(6.36377 7.08008)"/>
            </clipPath>
            </defs>
          </svg>
          <p className="ml-[13px] text-[#62708d] text-[13px] font-medium font-['Manrope'] leading-none">{Math.round(quote.end_time - quote.start_time)} Seconds.・{formatDateForQuotes(Math.round((meeting.start || meeting.created_at) + (quote.end_time - quote.start_time) * 1000))}</p>
        </div>
      </button>
  )
}

Quote.propTypes = {
  quote: PropTypes.object,
  index: PropTypes.number,
  setCurrentQuote: PropTypes.func
}

import React, { useContext, useRef } from 'react'
import { Note } from './note'
// import useOutsideClick from '../../../hooks/useOutsideClick'
import { ProjectContext } from '../../../context/ProjectContext'
import PropTypes from 'prop-types'

export const Notes = ({ notes, meetingId }) => {
  const {
    setSelectedMeeting,
    selectedNotes,
    setSelectedNotes,
    firstNoteSelected,
    setFirstNoteSelected,
    noteHover,
    setNoteHover
  } = useContext(ProjectContext)
  const notesRef = useRef(null)

  // useOutsideClick(notesRef, () => {
  //   setSelectedNotes([])
  //   setFirstNoteSelected(null)
  //   setNoteHover(null)
  // })

  const handleNoteSelect = (noteId, event) => {
    event.stopPropagation()
    if (event.ctrlKey || event.metaKey) {
      // Ctrl (or Cmd) key for multiple select
      setSelectedNotes(prevSelectedNotes =>
        prevSelectedNotes.includes(noteId)
          ? prevSelectedNotes.filter(id => id !== noteId)
          : [...prevSelectedNotes, noteId]
      )
      if (selectedNotes.length === 0) {
        setFirstNoteSelected(noteId)
        setNoteHover(noteId)
        if (meetingId) setSelectedMeeting(meetingId)
      }
    } else if (event.shiftKey) {
      // Shift key for range select
      if (selectedNotes.length === 0) {
        setSelectedNotes([noteId])
        setFirstNoteSelected(noteId)
        setNoteHover(noteId)
        if (meetingId) setSelectedMeeting(meetingId)
        return
      }
      const currentNoteIndex = notes.findIndex(note => note.id === noteId)
      const firstNoteIndex = notes.findIndex(note => note.id === firstNoteSelected)

      if (currentNoteIndex < firstNoteIndex) {
        setSelectedNotes(notes.slice(currentNoteIndex, firstNoteIndex + 1).map(note => note.id))
      } else if (currentNoteIndex > firstNoteIndex) {
        setSelectedNotes(notes.slice(firstNoteIndex, currentNoteIndex + 1).map(note => note.id))
      }
    } else {
      // Single select
      setSelectedNotes([noteId])
      setFirstNoteSelected(noteId)
      setNoteHover(noteId)
      if (meetingId) setSelectedMeeting(meetingId)
    }
  }

  return (
      <div className='w-full flex flex-wrap pt-5 relative' ref={notesRef} onClick={() => {
        setSelectedNotes([])
        setFirstNoteSelected(null)
        setNoteHover(null)
      }}>
        {notes.map(note =>
          <Note
            key={note.id}
            meetingId={meetingId || note.meeting_id}
            note={note}
            selected={selectedNotes.includes(note.id)}
            handleNoteSelect={handleNoteSelect}
            noteHover={noteHover}
            setNoteHover={setNoteHover}
          />
        )}
      </div>
  )
}

Notes.propTypes = {
  notes: PropTypes.array,
  meetingId: PropTypes.string
}

import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

export const Rename = ({ onClose, handleRename }) => {
  const dialogRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClose])

  return (
    <div ref={dialogRef} className="absolute top-[25px] py-2.5 px-4 bg-white rounded shadow z-10">
      <button className='flex justify-center items-center' onClick={handleRename}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1444_3385)">
          <path d="M7 7H6C5.46957 7 4.96086 7.21071 4.58579 7.58579C4.21071 7.96086 4 8.46957 4 9V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H15C15.5304 20 16.0391 19.7893 16.4142 19.4142C16.7893 19.0391 17 18.5304 17 18V17" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M20.385 6.58511C20.7788 6.19126 21.0001 5.65709 21.0001 5.10011C21.0001 4.54312 20.7788 4.00895 20.385 3.61511C19.9912 3.22126 19.457 3 18.9 3C18.343 3 17.8088 3.22126 17.415 3.61511L9 12.0001V15.0001H12L20.385 6.58511Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M16 5L19 8" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
          <defs>
          <clipPath id="clip0_1444_3385">
          <rect width="24" height="24" fill="white"/>
          </clipPath>
          </defs>
        </svg>
        <p className="ml-2 text-zinc font-semibold font-['Manrope']">Rename</p>
      </button>
    </div>
  )
}

Rename.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleRename: PropTypes.func.isRequired
}

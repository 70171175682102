import React from 'react'
import { QA } from './qa'
import PropTypes from 'prop-types'

export function QAs ({ QAs }) {
  return (
    <div className='w-full'>
      <div className='pb-[10px] border-b-2 border-grey-200'>
        <p className="w-full text-[#40424A] text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">Q&A</p>
      </div>

      {QAs.map((qa, index) => (
        <div key={index}>
          <QA question={qa.key_question} answer={qa.answers_overview} />
        </div>
      ))}
    </div>
  )
}

QAs.propTypes = {
  QAs: PropTypes.array
}

import React, { useContext } from 'react'
import { Need } from './need'
// import { StarsFilter } from './filters/stars'
// import { TagsFilter } from './filters/tags'
// import { MeetingsFilter } from './filters/meetings'
// import { CategoriesFilter } from './filters/categories'
import { MeetingsContext } from '../../../../context/MeetingContext'

export function Needs () {
  const { needs } = useContext(MeetingsContext)
  // const [filterNeeds, setFilterNeeds] = useState([])

  // console.log('filterneeds', filterNeeds)
  // useEffect(() => {
  //   setFilterNeeds(needs)
  //   // setFilterNeeds(needs.slice(0, 12))
  // }, [])

  return (
    <>
      {/* <div className="h-12 bg-white rounded-[10px] flex item-center" style={{ boxShadow: '0px 0px 16px 8px rgba(0, 0, 0, 0.15)' }}>
        <StarsFilter />
        <TagsFilter />
        <MeetingsFilter />
        <CategoriesFilter />
      </div> */}

      {/* <div className='w-full flex flex-wrap pt-5 relative' ref={notesRef} onClick={() => {
        setSelectedNotes([])
        setFirstNoteSelected(null)
        setNoteHover(null)
      }}> */}
      <div className='w-full flex flex-wrap ml-[44px] relative'>
        {needs.map(need => <Need key={need.id} need={need} />)}
      </div>
    </>
  )
}

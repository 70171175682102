import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import { customFetch } from '../../../utils'
import toast from 'react-hot-toast'
import { Card } from './card'
import { RequestCard } from './requestCard'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SwitchCalendarDialog } from './switchCalendarDialog'
import { createPortal } from 'react-dom'

export function Integrations () {
  const { user, setUser } = useContext(AuthContext)
  const [connected, setConnected] = useState([])
  const [disconnected, setDisconnected] = useState([])
  const [products, setProducts] = useState([])
  const [showSwitchDialog, setShowSwitchDialog] = useState('') // googleCalendar, microsoftCalendar
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const signInMethod = localStorage.getItem('signin_method')

  useEffect(() => {
    if (!user.integrations || !Object.keys(user.integrations).length) return
    const code = searchParams.get('code')
    const state = searchParams.get('state')

    const connected = []
    const disconnected = []
    for (const key of Object.keys(user.integrations)) {
      if (user.integrations[key]) connected.push(key)
      else disconnected.push(key)

      // If the user is not connected, but has a code in the URL, connect them.
      if (code && ((state === 'microsoftCalendar' && key === 'microsoftCalendar') || (!state && key === 'zoom'))) {
        if (!user.integrations[key]) handleConnect(key, code)
        // If already connected, remove code from URL
        else navigate('/dashboard/integrations')
      }
    }
    setConnected(connected)
    setDisconnected(disconnected)
  }, [user.integrations])

  useEffect(() => {
    async function getProducts () {
      try {
        const response = await customFetch('/getProductRequests', 'GET', null)
        if (response.error) throw new Error(response.error)
        response.products.sort((a, b) => a.order - b.order)
        setProducts(response.products)
      } catch (error) {
        console.error('Error getting products:', error)
      }
    }
    getProducts()
  }, [])

  const handleRequest = async (id, name) => {
    try {
      const response = await customFetch('/createRequest', 'POST', { productId: id, productName: name })
      if (response.error) throw new Error(response.error)
      setProducts(prevProducts => prevProducts.map(product => product.id === id ? { ...product, requested: true } : product))
    } catch (error) {
      console.error('Error getting products:', error)
    }
  }

  function prepareHandleConnect (key, showDialog) {
    if (key === 'zoom') return zoomSignIn()
    if (showDialog) {
      if (key === 'googleCalendar' && connected.includes('microsoftCalendar')) return setShowSwitchDialog(key)
      if (key === 'microsoftCalendar' && connected.includes('googleCalendar')) return setShowSwitchDialog(key)
    } else {
      setShowSwitchDialog('')
    }
    if (key === 'googleCalendar') {
      if (signInMethod === 'google') return handleConnect('googleCalendar', null)
      else return googleCalendarSignIn()
    }
    if (key === 'microsoftCalendar') {
      if (signInMethod === 'microsoft') return handleConnect('googlmicrosoftCalendar', null)
      else return microsftCalendarSignIn()
    }
  }

  async function handleConnect (key, code) {
    try {
      const response = await customFetch('/auth/connectIntegration', 'PUT', { key, code: code || searchParams.get('code') })
      if (response.error) throw new Error(response.error)
      if (response.url) {
        window.location.href = response.url
        return
      }

      if (key === 'googleCalendar') {
        setUser(prevUser => ({ ...prevUser, integrations: { ...prevUser.integrations, googleCalendar: true, microsoftCalendar: false } }))
      } else if (key === 'microsoftCalendar') {
        setUser(prevUser => ({ ...prevUser, integrations: { ...prevUser.integrations, googleCalendar: false, microsoftCalendar: true } }))
      } else {
        setUser(prevUser => ({ ...prevUser, integrations: { ...prevUser.integrations, [key]: true } }))
      }
    } catch (error) {
      console.error('Error connecting:', error, key)
      toast.error('Error connecting')
    }
  }

  async function handleDisconnect (key) {
    try {
      const response = await customFetch('/auth/disconnectIntegration', 'PUT', { key })
      if (response.error) throw new Error(response.error)
      setUser(prevUser => ({ ...prevUser, integrations: { ...prevUser.integrations, [key]: false } }))
    } catch (error) {
      console.error('Error disconnecting:', error, key)
      toast.error('Error disconnecting')
    }
  }

  const zoomSignIn = async () => {
    try {
      const response = await customFetch('/auth/getZoomUrl', 'POST', {})
      if (response.error) throw new Error(response.error)
      if (response.url) window.location.href = response.url
    } catch (error) {
      console.error('Error searching zoom:', error)
      toast.error('Error connecting')
    }
  }

  const googleCalendarSignIn = async () => {
    window.google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar.readonly',
      ux_mode: 'popup',
      callback: async (response) => handleConnect('googleCalendar', response.code)
    }).requestCode()
  }

  const microsftCalendarSignIn = async () => {
    const clientId = process.env.REACT_APP_MICROSOFT_CLIENT_ID
    const redirectUri = encodeURIComponent(process.env.REACT_APP_CLIENT_URL + '/dashboard/integrations')
    const state = encodeURIComponent('microsoftCalendar')
    const scopes = encodeURIComponent('User.Read Calendars.Read offline_access')
    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scopes}&response_mode=query&state=${state}`
  }

  return (
    <>
    <div className='ml-[39px] mt-[31px] flex flex-col gap-[37px]'>
      <p className="text-[#3f424a] text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">Integrations</p>

      {!!connected.length &&
        <div>
          <p className="mb-[15px] text-[#62708d] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">Already Connected</p>
          <div className='flex items-center gap-[19px]'>
            {connected.map(key =>
              <Card
                key={key}
                connected={true}
                name={getName(key)}
                description={getDescription(key)}
                logo={getLogo(key)}
                handleDisconnect={() => handleDisconnect(key)}
              />
            )}
          </div>
        </div>
      }
      <div>
        <p className="mb-[15px] text-[#62708d] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">Other Integrations</p>
        <div className='flex items-center gap-[19px] flex-wrap'>
          {disconnected.map(key =>
            <Card
              key={key}
              connected={false}
              name={getName(key)}
              description={getDescription(key)}
              logo={getLogo(key)}
              handleConnect={() => prepareHandleConnect(key, true)}
            />
          )}
          {products.map(product => (
            <RequestCard
              key={product.id}
              requested={product.requested}
              logo={<div dangerouslySetInnerHTML={{ __html: product.icon }} />}
              name={product.name}
              description={product.description}
              handleRequest={() => handleRequest(product.id, product.name)}
            />
          ))}
        </div>
      </div>
    </div>
    {showSwitchDialog && createPortal(
      <SwitchCalendarDialog handleClose={() => setShowSwitchDialog('')} handleSwitch={() => prepareHandleConnect(showSwitchDialog, false)} />,
      document.body
    )}
    </>
  )
}

function getName (key) {
  if (key === 'googleCalendar') return 'Google Calendar'
  if (key === 'microsoftCalendar') return 'Microsoft Calendar'
  if (key === 'zoom') return 'Zoom'
}

function getDescription (key) {
  if (key === 'googleCalendar') return 'Connecting to Google Calendar allows the Usermuse bot to join meetings without being manually added.'
  if (key === 'microsoftCalendar') return 'Connecting to Outlook allows the Usermuse bot to join meetings without being manually added.'
  if (key === 'zoom') return 'You must be connected to Zoom in order for the Usermuse bot to be able to join and analyze your meetings.'
}

function getLogo (key) {
  if (key === 'googleCalendar') {
    return <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M60.0084 18.7083L41.592 16.6621L19.0827 18.7083L17.0361 39.1713L19.0824 59.6344L39.5454 62.1922L60.0084 59.6344L62.0546 38.6601L60.0084 18.7083Z" fill="white"/>
        <path d="M27.4778 50.4556C25.9482 49.4222 24.8892 47.9133 24.311 45.918L27.8615 44.455C28.1838 45.6828 28.7464 46.6342 29.5496 47.3095C30.3478 47.9848 31.3198 48.3173 32.4555 48.3173C33.6168 48.3173 34.6145 47.9642 35.448 47.2582C36.2816 46.5521 36.7015 45.6517 36.7015 44.5623C36.7015 43.4472 36.2614 42.5363 35.3816 41.8306C34.5017 41.125 33.3968 40.7715 32.0768 40.7715H30.0255V37.2572H31.8669C33.0025 37.2572 33.9594 36.9505 34.7369 36.3366C35.5145 35.7227 35.9033 34.8836 35.9033 33.8145C35.9033 32.8631 35.5554 32.1057 34.8598 31.5381C34.1642 30.9704 33.284 30.6839 32.2148 30.6839C31.1713 30.6839 30.3424 30.9603 29.7285 31.5179C29.1146 32.0754 28.6694 32.7608 28.3883 33.5691L24.874 32.1061C25.3394 30.7861 26.194 29.6198 27.4471 28.612C28.7005 27.6043 30.3016 27.0977 32.2455 27.0977C33.6829 27.0977 34.9772 27.3741 36.1234 27.9316C37.2692 28.4892 38.1696 29.2617 38.8193 30.2438C39.469 31.2309 39.7913 32.3363 39.7913 33.5637C39.7913 34.8172 39.4896 35.8759 38.8858 36.7456C38.282 37.6153 37.5401 38.2802 36.6603 38.7459V38.9555C37.8216 39.4415 38.768 40.1833 39.5148 41.1809C40.2567 42.1786 40.6299 43.3706 40.6299 44.7621C40.6299 46.1536 40.2769 47.3966 39.5708 48.4864C38.8648 49.5762 37.8877 50.4354 36.6498 51.0594C35.4068 51.6834 34.0103 52.0007 32.4602 52.0007C30.6647 52.0058 29.0073 51.489 27.4778 50.4556Z" fill="#1A73E8"/>
        <path d="M49.2648 32.8385L45.387 35.6572L43.438 32.7005L50.4312 27.6562H53.112V51.4493H49.2648V32.8385Z" fill="#1A73E8"/>
        <path d="M60.008 78.0499L78.4244 59.6335L69.2162 55.541L60.008 59.6335L55.9155 68.8417L60.008 78.0499Z" fill="#EA4335"/>
        <path d="M14.9902 68.841L19.0827 78.0492H60.0083V59.6328H19.0827L14.9902 68.841Z" fill="#34A853"/>
        <path d="M6.80469 0.291016C3.41323 0.291016 0.666016 3.03823 0.666016 6.42969V59.633L9.87422 63.7255L19.0824 59.633V18.7074H60.008L64.1005 9.49922L60.0084 0.291016H6.80469Z" fill="#4285F4"/>
        <path d="M0.666016 59.6328V71.9105C0.666016 75.3024 3.41324 78.0492 6.80469 78.0492H19.0824V59.6328H0.666016Z" fill="#188038"/>
        <path d="M60.0083 18.7077V59.6333H78.4247V18.7077L69.2165 14.6152L60.0083 18.7077Z" fill="#FBBC04"/>
        <path d="M78.4247 18.7074V6.42969C78.4247 3.03785 75.6775 0.291016 72.286 0.291016H60.0083V18.7074H78.4247Z" fill="#1967D2"/>
      </svg>
  }
  if (key === 'microsoftCalendar') {
    return <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.0254 21.3496H69.5685C70.8807 21.3496 71.945 22.4139 71.945 23.7261V54.6126C71.945 55.9248 70.8807 56.9891 69.5685 56.9891H46.0254V21.3496Z" fill="#1976D2"/>
      <path d="M68.7051 29.3809H46.0254V54.5585H68.7051V29.3809Z" fill="white"/>
      <path d="M44.4054 71.5685L7.146 64.2787V14.0594L44.4054 6.76953V71.5685Z" fill="#1976D2"/>
      <path d="M25.3705 27.0195C20.2255 27.0195 16.0557 32.4594 16.0557 39.1693C16.0557 45.8793 20.2255 51.3192 25.3705 51.3192C30.5156 51.3192 34.6854 45.8793 34.6854 39.1693C34.6854 32.4594 30.5156 27.0195 25.3705 27.0195ZM24.9655 46.4592C22.2812 46.4592 20.1056 43.195 20.1056 39.1693C20.1056 35.1437 22.2812 31.8795 24.9655 31.8795C27.6498 31.8795 29.8255 35.1437 29.8255 39.1693C29.8255 43.195 27.6498 46.4592 24.9655 46.4592Z" fill="white"/>
      <path d="M50.4755 48.4629H46.1016V53.1608H50.4755V48.4629Z" fill="#1976D2"/>
      <path d="M55.9848 48.4629H51.6108V53.1608H55.9848V48.4629Z" fill="#1976D2"/>
      <path d="M61.4945 48.4629H57.1206V53.1608H61.4945V48.4629Z" fill="#1976D2"/>
      <path d="M50.4755 42.666H46.1016V47.3639H50.4755V42.666Z" fill="#1976D2"/>
      <path d="M55.9848 42.666H51.6108V47.3639H55.9848V42.666Z" fill="#1976D2"/>
      <path d="M61.4945 42.666H57.1206V47.3639H61.4945V42.666Z" fill="#1976D2"/>
      <path d="M67.0038 42.666H62.6299V47.3639H67.0038V42.666Z" fill="#1976D2"/>
      <path d="M50.4755 37.0723H46.1016V41.7702H50.4755V37.0723Z" fill="#1976D2"/>
      <path d="M55.9848 37.0723H51.6108V41.7702H55.9848V37.0723Z" fill="#1976D2"/>
      <path d="M61.4945 37.0723H57.1206V41.7702H61.4945V37.0723Z" fill="#1976D2"/>
      <path d="M67.0038 37.0723H62.6299V41.7702H67.0038V37.0723Z" fill="#1976D2"/>
      <path d="M55.9848 31.25H51.6108V35.9479H55.9848V31.25Z" fill="#1976D2"/>
      <path d="M61.4945 31.25H57.1206V35.9479H61.4945V31.25Z" fill="#1976D2"/>
      <path d="M67.0038 31.25H62.6299V35.9479H67.0038V31.25Z" fill="#1976D2"/>
    </svg>
  }
  if (key === 'zoom') {
    return <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4769_5830)">
        <path d="M66.761 0.289062H12.3298C5.88809 0.289062 0.666016 5.51113 0.666016 11.9529V66.3841C0.666016 72.8258 5.88809 78.0479 12.3298 78.0479H66.761C73.2028 78.0479 78.4248 72.8258 78.4248 66.3841V11.9529C78.4248 5.51113 73.2028 0.289062 66.761 0.289062Z" fill="#2D8CFF"/>
        <path d="M65.6674 54.508C66.8824 54.8118 67.9455 54.2043 68.553 53.2931C68.8567 52.8374 68.8567 52.0781 68.8567 50.4075V27.4747C68.8567 25.8041 68.8567 25.1966 68.553 24.5891C68.0974 23.3741 66.8824 22.9185 65.6674 23.3741C62.4781 25.5003 55.4919 31.7271 55.3401 34.309C55.2186 34.7646 55.2186 35.5239 55.2186 36.5871V42.3582C55.2186 43.5732 55.2186 44.0288 55.3401 44.6363C55.4919 45.8513 55.9476 46.9144 56.5551 47.5219C58.3775 48.8887 64.4524 54.3562 65.8193 54.3562L65.6674 54.508ZM10.3857 28.6897C10.3857 26.4116 10.3857 25.1966 10.8414 24.5891C11.1451 23.9816 12.0563 23.3741 12.512 22.9185C13.1195 22.4629 14.1826 22.4629 16.6125 22.4629H36.2041C41.9753 22.4629 44.8609 22.4629 47.1389 23.6779C48.8095 24.8929 50.632 25.956 51.6951 28.2341C52.9101 30.5121 52.9101 33.3977 52.9101 39.1689V49.4962C52.9101 51.7743 52.9101 52.9893 52.4545 53.5968C52.1507 54.2043 51.2395 54.8118 50.7839 55.2674C50.1764 55.723 49.1133 55.723 46.6833 55.723H27.0917C21.3206 55.723 18.435 55.723 16.1569 54.508C14.4863 53.2931 12.6638 52.2299 11.6007 49.9519C10.3857 47.6738 10.3857 44.7882 10.3857 39.017V28.6897Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_4769_5830">
        <rect width="77.7588" height="77.7588" fill="white" transform="translate(0.666016 0.289062)"/>
        </clipPath>
        </defs>
      </svg>
  }
}

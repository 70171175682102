import React from 'react'
import MeetingExample from '../../../assets/signin-meeting-example.png'
import OpinionExample from '../../../assets/signin-opinion.png'
import { CheckMark } from './checkMark'

export function Sidebar () {
  return (
        <div className='mt-14'>
            <img src={MeetingExample} alt="meeting-example" className="w-[305px] h-[205px] mx-auto mb-8"/>
            <div className="mx-14 pl-28">
                {['Perfect user research on every call',
                  'Miss nothing, no matter how fast it goes by',
                  'Never feel overwhelmed'].map((text, index) => <CheckMark text={text} key={index} />)}
            </div>
            <img src={OpinionExample} alt="opinion-example" className="w-[405px] h-[222px] mx-auto mt-12"/>
        </div>
  )
}

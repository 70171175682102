import React, { useContext } from 'react'
import { MeetingsContext } from '../../../../context/MeetingContext'
import { Feedback } from './feedback'

export function Feedbacks () {
  const { feedbacks } = useContext(MeetingsContext)

  return (
      <div className='w-full flex flex-wrap ml-[44px] relative pb-[40px]'>
        {feedbacks.map(feedback => <Feedback key={feedback.id} feedback={feedback} />)}
      </div>
  )
}

import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Radio, Space } from 'antd'
import useOutsideClick from '../../../../hooks/useOutsideClick'

export function ProjectFilter ({ projects, projectIndex, setProjectIndex }) {
  const [openProjectFilter, setOpenProjectFilter] = useState(false)
  const projectFilterRef = useRef(null)

  useOutsideClick(projectFilterRef, () => {
    if (projectFilterRef) {
      setOpenProjectFilter(false)
    }
  })

  return (
      <div className='relative' ref={projectFilterRef}>
        <button className='flex items-center gap-[8px] px-[20px]' onClick={() => setOpenProjectFilter(!openProjectFilter)}>
          <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.07617 1.17188H17.2762V12.2173C17.2762 12.4126 17.1986 12.5999 17.0605 12.738C16.9224 12.8761 16.7351 12.9537 16.5398 12.9537H12.858C12.6627 12.9537 12.4754 12.8761 12.3373 12.738C12.1992 12.5999 12.1216 12.4126 12.1216 12.2173V10.0082H6.23072V15.1628C6.23072 15.3581 6.15314 15.5454 6.01504 15.6835C5.87695 15.8216 5.68965 15.8991 5.49435 15.8991H1.81254C1.61724 15.8991 1.42994 15.8216 1.29185 15.6835C1.15375 15.5454 1.07617 15.3581 1.07617 15.1628V1.17188Z" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.23072 7.0625H1.07617" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.23047 1.17188V10.0082" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.1221 7.0625H17.2766" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.1221 1.17188V10.0082" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <p className="text-[#3f424a] text-base font-semibold font-['Manrope'] leading-tight">{projectIndex === -1 ? 'Any Project' : projects[projectIndex].name}</p>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3983_17643)">
            <circle cx="9.88218" cy="9.82065" r="7.27964" fill="#EBEBEB"/>
            <path d="M12.3082 9.01367L9.88162 11.4402L7.45508 9.01367" stroke="#40424A" strokeWidth="1.3866" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_3983_17643">
            <rect width="19.4124" height="19.4124" fill="white" transform="translate(0.175781 0.115234)"/>
            </clipPath>
            </defs>
          </svg>
        </button>
        {openProjectFilter && (
          <div className="absolute w-auto left-[-1px] top-8 p-2 bg-white shadow rounded-b border-x border-grey-200 z-10 h-[260px] overflow-auto hideScrollbar">
            <Radio.Group onChange={(e) => setProjectIndex(e.target.value)} value={projectIndex}>
              <Space direction="vertical">
              <Radio value={-1}>Any Project</Radio>
                {projects.map((project, index) => (
                  <Radio key={index} value={index}>{project.name}</Radio>
                ))}
              </Space>
            </Radio.Group>
          </div>
        )}
      </div>
  )
}

ProjectFilter.propTypes = {
  projects: PropTypes.array,
  projectIndex: PropTypes.number,
  setProjectIndex: PropTypes.func
}

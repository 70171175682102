import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../context/AuthContext'
import { customFetch } from '../../../../utils'
import { Interview } from './interview'
// import { InterviewDialog } from './interviewDialog'
import { Event } from './event'
// import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

export function Interviews ({ sessions }) {
  const [events, setEvents] = useState([])
  // const [currentInterview, setCurrentInterview] = useState(-1)
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (typeof user !== 'object' || Object.keys(user).length === 0) return
    const getCalendarEvents = async () => {
      const url = '/getCalendarEvents'
      const data = await customFetch(url, 'GET', null)
      if (data.error) return
      setEvents(data.events)
    }

    getCalendarEvents()
  }, [user])

  return (
      <div className='w-full'>
        <div className='mb-[28px] pb-[10px] flex justify-between items-center border-b-2 border-grey-200'>
          <p className="w-full text-[#40424A] text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">Interviews</p>
          {/* <button className="w-[91.41px] h-[34.76px] px-[16.55px] bg-white rounded-[49.65px] shadow border-2 border-[#E4E4E4] flex items-center gap-[3.31px]">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_3498_24321)">
              <path d="M10.1367 4.83008V16.4967" stroke="#FF4A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M4.30859 10.6641H15.9753" stroke="#FF4A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_3498_24321">
              <rect width="20" height="20" fill="white" transform="translate(0.138672 0.664062)"/>
              </clipPath>
              </defs>
            </svg>
            <p className="text-[#40424A] text-lg font-medium font-['DM_Sans'] leading-tight">Add</p>
          </button> */}
        </div>

        {events.length > 0 && (
          <>
          <p className="text-[#62718D] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Upcoming</p>
          <div className='my-[14px] flex items-center flex-wrap gap-4'>
            {events.map(event => <Event key={event.id} event={event} />)}
          </div>
          </>
        )}

        <p className="text-[#62718D] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Recorded</p>
        <div className='my-[14px] flex items-center flex-wrap gap-4'>
          {sessions.map((meet, index) => (
            <button key={meet.id} onClick={() => navigate(`/dashboard/meetings/${meet.id}`)} className="w-[365px] h-[79.37px] p-4 bg-white rounded-lg shadow border border-grey-200 justify-start items-center gap-4 inline-flex">
              <Interview meet={meet} />
            </button>
          ))}
        </div>

        {/* {currentInterview >= 0 && createPortal(
          <InterviewDialog
            meet={sessions[currentInterview]}
            currentInterview={currentInterview}
            thumbnail={sessions[currentInterview]?.thumbnail}
            next={currentInterview > 0 ? () => setCurrentInterview(prev => prev - 1) : null}
            prev={currentInterview < sessions.length - 1 ? () => setCurrentInterview(prev => prev + 1) : null}
            closeDialog={() => setCurrentInterview(-1)}
          />,
          document.body
        )} */}
      </div>
  )
}

Interviews.propTypes = {
  sessions: PropTypes.array
}

import React, { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'

export function MicrosoftSignIn () {
  const { microsoftSignIn } = useContext(AuthContext)

  async function handleMicrosoftSignIn () {
    try {
      await microsoftSignIn()
    } catch (error) {
      console.error(error)
    }
  }

  return (
      <div className="mt-8 mb-4">
          <button onClick={handleMicrosoftSignIn} className="w-[350px] h-[50px] rounded-md border border-black flex items-center justify-between px-8">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" width="24" height="24">
              <path fill="#f35325" d="M0 0h10v10H0z"/>
              <path fill="#81bc06" d="M11 0h10v10H11z"/>
              <path fill="#05a6f0" d="M0 11h10v10H0z"/>
              <path fill="#ffba08" d="M11 11h10v10H11z"/>
            </svg>
            <p className="text-black text-l font-medium font-['DM_Sans'] -ml-8">Continue with Microsoft</p>
            <div className="justify-end">
              <svg width="25" height="15" viewBox="0 0 31 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_664_4290)">
                <path d="M1.79492 9.40625H29.7341" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M21.752 17.3889L29.7346 9.40625" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M21.752 1.42383L29.7346 9.40644" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_664_4290">
                <rect width="31" height="19" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </div>
          </button>
      </div>
  )
}

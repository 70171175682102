import React, { useContext, useState } from 'react'
import { MeetingsContext } from '../../../../context/MeetingContext'
import { createPortal } from 'react-dom'
import { QuoteDialog } from './quoteDialog'
import { Quote } from './quote'

export function Quotes () {
  const { quotes, meeting, setQuotesContainerRef } = useContext(MeetingsContext)
  const [currentQuote, setCurrentQuote] = useState(-1)

  return (
    <>
      <div className='flex'>
        <div className="flex gap-[20px] overflow-auto hideScrollbar" ref={setQuotesContainerRef}>
          {quotes.map((quote, index) => <Quote key={quote.id} quote={quote} index={index} setCurrentQuote={setCurrentQuote} />)}
        </div>
      </div>

      {currentQuote >= 0 && createPortal(
        <QuoteDialog
          meet={meeting}
          quote={quotes[currentQuote]}
          currentQuote={currentQuote}
          totalQuotes={quotes.length}
          thumbnail={meeting.thumbnail}
          next={currentQuote > 0 ? () => setCurrentQuote(prev => prev - 1) : null}
          prev={currentQuote < quotes.length - 1 ? () => setCurrentQuote(prev => prev + 1) : null}
          closeDialog={() => setCurrentQuote(-1)}
        />,
        document.body
      )}
    </>
  )
}

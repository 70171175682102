import React from 'react'
import PropTypes from 'prop-types'

export function RequestCard ({ requested, logo, name, description, handleRequest }) {
  return (
      <div className='w-[225px] h-[303px] bg-white p-[16.37px] rounded border border-[#e4e4e4] flex flex-col justify-between gap-[14px]'>
        <div className='p-[16.37px] flex justify-center items-center'>
          {logo}
        </div>

        <p className="text-[#3f424a] text-sm font-semibold font-['Manrope'] leading-[17.50px]">{name}</p>

        <p className="text-[#62708d] text-xs font-medium font-['Manrope'] leading-[15px]">{description}</p>

        <button disabled={requested} className={`w-full h-[29px] p-[16.37px] flex flex-col justify-center items-center ${requested ? 'bg-[#f8f8f8]' : 'bg-white'}`} style={{ boxShadow: '0px 0px 18.334714889526367px rgba(147.93, 159.11, 217.81, 0.05)', borderRadius: 6.71, border: '0.84px #E4E4E4 solid' }} onClick={handleRequest}>
          <p className="text-[#3f424a] text-[13px] font-semibold font-['Manrope'] leading-none">{requested ? 'Requested. Thanks!' : 'Request'}</p>
        </button>
      </div>
  )
}

RequestCard.propTypes = {
  requested: PropTypes.bool,
  logo: PropTypes.node,
  name: PropTypes.string,
  description: PropTypes.string,
  handleRequest: PropTypes.func
}

import React, { useContext, useEffect, useRef, useState } from 'react'
import { ProjectContext } from '../../../../context/ProjectContext'
import { customFetch, timeAgo } from '../../../../utils'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import Tippy from '@tippyjs/react'
import { useNavigate } from 'react-router-dom'
import { Facilitator } from './facilitator'

export function Facilitators () {
  const { project, setProject } = useContext(ProjectContext)
  const [facilitators, setFacilitators] = useState([])
  const [showUsers, setShowUsers] = useState(false)
  const [users, setUsers] = useState([])
  const addFacilitatorRef = useRef(null)
  const navigate = useNavigate()

  useOutsideClick(addFacilitatorRef, () => {
    if (addFacilitatorRef) {
      setShowUsers(false)
    }
  })

  useEffect(() => {
    const getUsers = async () => {
      const facilitatorsDb = []
      const response = await customFetch(`/getUsersByWorkspace/${project.workspace_id}`, 'GET', null)
      for (const facilitator of project.facilitators) {
        const userIndex = response.users.findIndex(user => user.id === facilitator)
        if (userIndex === -1) return console.log('Facilitator not found in users')
        facilitatorsDb.push(response.users[userIndex])
        response.users.splice(userIndex, 1) // 2nd parameter means remove one item only
      }
      setUsers(response.users)
      setFacilitators(facilitatorsDb)
    }
    getUsers()
  }, [])

  async function addFacilitator (userId) {
    const user = users.find(user => user.id === userId)
    setUsers(prevUsers => prevUsers.filter(user => user.id !== userId))
    setFacilitators(prevFacilitators => [...prevFacilitators, user])
    setProject(prevProject => ({ ...prevProject, facilitators: [...prevProject.facilitators, userId] }))
    await customFetch('/updateProjectFacilitators', 'PUT', { projectId: project.id, facilitators: [...facilitators.map(user => user.id), userId] })
  }

  async function removeFacilitator (userId) {
    const facilitator = facilitators.find(user => user.id === userId)
    setFacilitators(prevFacilitators => prevFacilitators.filter(user => user.id !== userId))
    setUsers(prevUsers => [...prevUsers, facilitator])
    setProject(prevProject => ({ ...prevProject, facilitators: prevProject.facilitators.filter(user => user !== userId) }))
    await customFetch('/updateProjectFacilitators', 'PUT', { projectId: project.id, facilitators: facilitators.filter(user => user.id !== userId).map(user => user.id) })
  }

  return (
    <div className='mt-[24px] pb-[20px] border-b-2 border-grey-200'>
      <div>
        <span className="text-[#40424A] text-base font-semibold font-['Manrope'] leading-tight">Facilitator</span>
        <span className="text-[#D40814] text-base font-semibold font-['Manrope'] leading-tight">*</span>
      </div>
      <p className="mt-[2px] text-[#62718D] text-[13px] font-bold font-['Manrope'] leading-none">Add facilitators to project.</p>

      <div className='mt-[8px] flex items-center flex-wrap gap-3'>
        {Array.isArray(facilitators) && facilitators.map((facilitator) => <Facilitator key={facilitator.id} facilitator={facilitator} removeFacilitator={removeFacilitator} />)}

        <div ref={addFacilitatorRef} className='relative'>
          <Tippy
            disabled={users.length}
            interactive={true}
            maxWidth={350}
            content={
              <div className='p-2 w-[300px] text-left'>
                Please add team members to this workspace to add other facilitators.
                <button className='text-linkBlue font-semibold' onClick={() => navigate('/dashboard/team')}>&nbsp;Team Settings</button>
              </div>
            }
          >
            <div>
            <button onClick={() => setShowUsers(true)} className={`ml-[14px] w-[100px] h-[44px] pl-2 pr-4 py-2 bg-white rounded-lg shadow border border-dashed border-[#FF4A8E] justify-start items-center gap-3 inline-flex ${!users.length && 'opacity-30'}`} disabled={!users.length}>
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3800_13751)">
                <path d="M17.9961 7.49805V28.498" stroke="#FF4A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.50586 18H28.5059" stroke="#FF4A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_3800_13751">
                <rect width="36" height="36" fill="white"/>
                </clipPath>
                </defs>
              </svg>
              <div className="flex-col justify-start items-start gap-[3px] inline-flex">
                <p className="text-[#40424A] text-base font-semibold font-['Manrope'] leading-tight">Add</p>
              </div>
            </button>
            </div>
          </Tippy>
          {showUsers && !!users.length && (
            <div className={`w-[270px] absolute ${facilitators.length > 0 ? 'right-0' : 'left-0'} top-[44px] p-2.5 bg-white shadow rounded flex-col justify-center items-start gap-2 inline-flex z-10`}>
              {users.map((user, index) => user.workspace.joined_at && (
                <button key={user.id} onClick={() => addFacilitator(user.id)} className={`w-full h-[62px] pb-2.5 bg-white justify-start items-center gap-[38px] inline-flex ${index < users.length - 1 && 'border-b border-[#e4e4e4]'}`}>
                  <img className="w-[38px] h-[38px] rounded-full border border-grey-200" src={user.picture} />
                  <div className="py-2 flex-col justify-start items-start inline-flex">
                    <p className="text-[#3f424a] text-base font-bold font-['Manrope'] leading-tight">{user.name}</p>
                    <p className="text-[#62708d] text-[13px] font-medium font-['Manrope'] leading-none">Joined {timeAgo(user.workspace.joined_at)}</p>
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import { UserDropdown } from './userDropdown'
import { DashboardContext } from '../../../context/DashboardContext'
import { createPortal } from 'react-dom'
import { ProjectLayout } from '../dialog/projectLayout'
import { useDebounce } from '../../../hooks/useDebounce'
import cnt from '../../../constants'
import Tippy from '@tippyjs/react'

export function Topbar () {
  const [link, setLink] = useState('')
  const [showDialog, setShowDialog] = useState(false)
  const [importFile, setImportFile] = useState(false)
  const { filterProjects, searchTerm, setSearchTerm, activeWorkspace } = useContext(DashboardContext)
  const debouncedProject = useDebounce(searchTerm, 500)

  useEffect(() => {
    filterProjects(searchTerm)
  }, [debouncedProject])

  return (
        <div className="h-8 flex flex-wrap items-center mx-[24px] my-[19px]">
            <div className="relative w-1/5 h-full">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.22222 13.4444C10.6587 13.4444 13.4444 10.6587 13.4444 7.22222C13.4444 3.78578 10.6587 1 7.22222 1C3.78578 1 1 3.78578 1 7.22222C1 10.6587 3.78578 13.4444 7.22222 13.4444Z" stroke="#62718D" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16.9993 16.9993L11.666 11.666" stroke="#62718D" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                <input
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    type="search"
                    className="h-full ps-10 text-sm placeholder-grey-400 text-grey-400 bg-grey-100 rounded border border-grey-200"
                    placeholder="Search interview..."
                />
            </div>
            <div className='w-1/5'></div>
            <div className="flex w-3/5 h-full">
                <Tippy disabled={activeWorkspace === 'active' || activeWorkspace.additional_amount + activeWorkspace.sub_hours_left > cnt.min_time_needed} maxWidth={300} content='This feature is disabled since your account is out of free hours. Please upgrade to continue using our AI features.'>
                <div className="relative grow mx-4">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg width="17" height="11" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.667 5.00061L18.4729 2.72461C18.6338 2.64844 18.8126 2.61249 18.9923 2.62015C19.1719 2.62782 19.3466 2.67885 19.4996 2.76841C19.6526 2.85797 19.7789 2.98308 19.8665 3.13188C19.9542 3.28067 20.0002 3.44822 20.0003 3.61861V10.3826C20.0002 10.553 19.9542 10.7205 19.8665 10.8693C19.7789 11.0181 19.6526 11.1432 19.4996 11.2328C19.3466 11.3224 19.1719 11.3734 18.9923 11.3811C18.8126 11.3887 18.6338 11.3528 18.4729 11.2766L13.667 9.00061V5.00061Z" stroke="#62718D" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M11.5556 1H3.11111C1.94518 1 1 1.89543 1 3V11C1 12.1046 1.94518 13 3.11111 13H11.5556C12.7215 13 13.6667 12.1046 13.6667 11V3C13.6667 1.89543 12.7215 1 11.5556 1Z" stroke="#62718D" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <input
                        disabled={activeWorkspace.status !== 'active' && activeWorkspace.additional_amount + activeWorkspace.sub_hours_left <= cnt.min_time_needed}
                        type="url"
                        id="default-search"
                        className="h-full w-full ps-10 text-sm placeholder-grey-400 text-grey-400 bg-grey-100 rounded border border-grey-200"
                        placeholder="Paste meeting URL to record"
                        onChange={e => setLink(e.target.value)}
                        onKeyDown={e => e.key === 'Enter' && (link ? setShowDialog(true) : alert('Please enter a link')) }
                        required
                    />
                </div>
                </Tippy>
                {/* <button className="flex mx-2 font-bold px-4 rounded border items-center bg-pink text-white shadow">
                    <svg width="12" height="16" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.78906 3.84211C4.78906 3.08833 5.0885 2.36543 5.6215 1.83243C6.15449 1.29944 6.87739 1 7.63117 1C8.38494 1 9.10784 1.29944 9.64084 1.83243C10.1738 2.36543 10.4733 3.08833 10.4733 3.84211V8.57895C10.4733 9.33272 10.1738 10.0556 9.64084 10.5886C9.10784 11.1216 8.38494 11.4211 7.63117 11.4211C6.87739 11.4211 6.15449 11.1216 5.6215 10.5886C5.0885 10.0556 4.78906 9.33272 4.78906 8.57895V3.84211Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1 8.57812C1 10.3369 1.69868 12.0237 2.94234 13.2674C4.18601 14.511 5.87278 15.2097 7.63158 15.2097C9.39038 15.2097 11.0772 14.511 12.3208 13.2674C13.5645 12.0237 14.2632 10.3369 14.2632 8.57812" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M3.8418 19H11.4207" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7.63184 15.2109V19.0004" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <p className='ml-2'>Record</p>
                </button> */}
                {showDialog && createPortal(
                    <ProjectLayout link={link} importFile={importFile} handleClose={() => {
                      setImportFile(false)
                      setShowDialog(false)
                    }} />,
                    document.body
                )}
                <Tippy disabled={activeWorkspace.status === 'active' || activeWorkspace.additional_amount + activeWorkspace.sub_hours_left > cnt.min_time_needed} maxWidth={300} content='This feature is disabled since your account is out of free hours. Please upgrade to continue using our AI features.'>
                <div>
                <button
                    disabled={activeWorkspace.status !== 'active' && activeWorkspace.additional_amount + activeWorkspace.sub_hours_left <= cnt.min_time_needed}
                    className={`flex h-full mx-2 font-bold px-4 rounded border items-center bg-white text-pink ${activeWorkspace.status !== 'active' && activeWorkspace.additional_amount + activeWorkspace.sub_hours_left <= cnt.min_time_needed && 'opacity-50'}`}
                    onClick={() => {
                      setImportFile(true)
                      setShowDialog(true)
                    }}
                >
                    <svg width="17" height="14" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.22205 12.2282C4.17222 12.2282 3.16539 11.8293 2.42305 11.1193C1.68071 10.4092 1.26367 9.44616 1.26367 8.44197C1.26367 7.43779 1.68071 6.47474 2.42305 5.76467C3.16539 5.05461 4.17222 4.6557 5.22205 4.6557C5.47 3.55109 6.19535 2.58038 7.23853 1.9571C7.75506 1.64849 8.33407 1.43446 8.94251 1.32723C9.55095 1.22001 10.1769 1.22169 10.7846 1.33219C11.3923 1.44268 11.9699 1.65982 12.4844 1.97121C12.9988 2.2826 13.4401 2.68214 13.783 3.14701C14.126 3.61189 14.3638 4.133 14.4829 4.6806C14.602 5.22819 14.6002 5.79154 14.4774 6.33849H15.3188C16.0998 6.33849 16.8489 6.64875 17.4011 7.20102C17.9534 7.75329 18.2637 8.50234 18.2637 9.28337C18.2637 10.0644 17.9534 10.8134 17.4011 11.3657C16.8489 11.918 16.0998 12.2282 15.3188 12.2282H14.4774" stroke="#FF4A8E" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6.90527 9.70387L9.42946 7.17969L11.9536 9.70387" stroke="#FF4A8E" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M9.42871 7.17969V14.7522" stroke="#FF4A8E" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <p className='ml-2'>Import</p>
                </button>
                </div>
                </Tippy>
                <UserDropdown />
            </div>
        </div>
  )
}

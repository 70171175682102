export const customFetch = async (urlPath, method, body) => {
  try {
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json'
      }
    }
    if (process.env.REACT_APP_NODE_ENV !== 'willUseHttpOnly') {
      options.headers.authorization = localStorage.getItem('access_token')
      options.headers.signinmethod = localStorage.getItem('signin_method')
    }
    if (body) options.body = JSON.stringify(body)

    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api${urlPath}`, options)

    if (!response.ok) throw await response.json()
    if (response.status === 401) throw new Error('unauthorized')

    if (process.env.REACT_APP_NODE_ENV !== 'willUseHttpOnly') {
      response.headers.forEach((value, key) => {
        if (key === 'set-access-token') {
          localStorage.setItem('access_token', value)
        }
      })
    }

    return await response.json()
  } catch (error) {
    console.error('Error fetching:', error)
    return { error }
  }
}

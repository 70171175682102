import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import { collection, doc, getDoc, getDocs, orderBy, query, where } from 'firebase/firestore'
import { db } from '../firebase'
import { useDebounce } from '../hooks/useDebounce'
import { customFetch } from '../utils'
import toast from 'react-hot-toast'
import { AuthContext } from './AuthContext'
import { NeedTooltip } from '../components/meeting/tooltips/needTooltip'
import { QuoteTooltip } from '../components/meeting/tooltips/quoteTooltip'
import { QATooltip } from '../components/meeting/tooltips/qaTooltip'
import { FeedbackTooltip } from '../components/meeting/tooltips/feedbackTooltip'
import cnt from '../constants'

export const MeetingsContext = createContext()

export const MeetingsContextProvider = ({ children }) => {
  const { user } = useContext(AuthContext)
  const param = useParams()
  const navigate = useNavigate()
  const [meetingLoading, setMeetingLoading] = useState(true)
  const [meeting, setMeeting] = useState({})
  const [project, setProject] = useState({})
  const [meetingOverview, setMeetingOverview] = useState({})
  const [participants, setParticipants] = useState({})
  const [transcripts, setTranscripts] = useState([])
  const [quotes, setQuotes] = useState([])
  const [qas, setQas] = useState([])
  const [feedbacks, setFeedbacks] = useState([])
  const [needs, setNeeds] = useState([])

  const initialData = async () => {
    const meetingId = param.meetingId
    const meetDb = await getDoc(doc(db, 'meetings', meetingId))
    if (!meetDb.exists()) {
      toast.error('No documents with that meeting id!')
      navigate('/dashboard/meetings')
      return
    }

    const projectDb = await getDoc(doc(db, 'projects', meetDb.data().project_id))
    if (!projectDb.exists()) {
      toast.error('No project with that meeting id!')
      navigate('/dashboard/meetings')
      return
    }
    if (!Object.keys(user.workspaces).includes(projectDb.data().workspace_id)) {
      toast.error('You do not have access to this meeting')
      navigate('/dashboard/meetings')
      return
    }
    setMeeting({ ...meetDb.data(), id: meetDb.id })
    setProject({ ...projectDb.data(), id: projectDb.id })

    const meetingOverviewDb = await getDoc(doc(db, 'meetingsOverview', meetingId))
    if (!meetingOverviewDb.exists()) {
      toast.error('No overview with that meeting id!')
      navigate('/dashboard/meetings')
      return
    }
    setMeetingOverview({ ...meetingOverviewDb.data() })

    const quotes = []
    let q = query(collection(db, 'quotes'), where('meetingId', '==', meetingId), orderBy('start_time', 'asc'))
    let querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        quotes.push({ ...doc.data(), id: doc.id })
      })
      setQuotes(quotes)
    }

    const qas = []
    q = query(collection(db, 'qas'), where('meetingId', '==', meetingId), orderBy('start_time', 'asc'))
    querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        qas.push({ ...doc.data(), id: doc.id })
      })
      setQas(qas)
    }

    const feedbacks = []
    q = query(collection(db, 'feedbacks'), where('meetingId', '==', meetingId), orderBy('start_time', 'asc'))
    querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        feedbacks.push({ ...doc.data(), id: doc.id })
      })
      setFeedbacks(feedbacks)
    }

    const needs = []
    q = query(collection(db, 'needs'), where('meeting_id', '==', meetingId), orderBy('start_time', 'asc'))
    querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        needs.push({ ...doc.data(), id: doc.id })
      })
      setNeeds(needs)
    }

    const participants = {}
    q = query(collection(db, 'participants'), where('meeting_id', '==', meetingId))
    querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        participants[doc.id] = {
          name: doc.data().name,
          role: doc.data().role
        }
      })
      setParticipants(participants)
    }

    q = query(collection(db, 'transcripts'), where('meeting_id', '==', meetingId))
    querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      const transcripts = []
      querySnapshot.forEach((doc) => {
        transcripts.push({ ...doc.data(), id: doc.id })
      })
      transcripts.sort((a, b) => {
        // Extract the first number in square brackets using a regular expression.
        const numberPattern = /\[(\d+(\.\d+)?)\]/
        const numberA = a.transcript.match(numberPattern) ? parseFloat(a.transcript.match(numberPattern)[1]) : 0
        const numberB = b.transcript.match(numberPattern) ? parseFloat(b.transcript.match(numberPattern)[1]) : 0
        return numberA - numberB
      })

      let lastSpeaker = ''
      const groupBySpeaker = []
      transcripts.forEach(doc => {
        if (lastSpeaker === doc.participant_id) {
          groupBySpeaker[groupBySpeaker.length - 1].transcript += '\n' + doc.transcript
        } else {
          lastSpeaker = doc.participant_id
          const time = doc.transcript.match(/\[[^\]]+\]/)[0]
          const timeSeconds = parseFloat(time.substring(1, time.length - 1))
          const hours = Math.floor(timeSeconds / 60 / 60)
          const minutes = Math.floor(timeSeconds / 60)
          const secondsLeft = Math.floor(timeSeconds % 60)
          const timeFormatted = `${hours}:${(minutes < 10 && '0') + minutes}:${(secondsLeft < 10 && '0') + secondsLeft}`
          groupBySpeaker.push({ participant_id: doc.participant_id, time: timeFormatted, transcript: doc.transcript })
        }
      })

      // Split the transcript into sentences
      const splittedTranscripts = []
      groupBySpeaker.forEach(doc => {
        const regex = /\[(\d+\.\d+)\]\s(.+)/g
        let match

        const sentences = []
        while ((match = regex.exec(doc.transcript)) !== null) {
          const start = parseFloat(match[1])
          const text = match[2]
          sentences.push({ text, start })
        }
        splittedTranscripts.push({ participant_id: doc.participant_id, time: doc.time, sentences })
      })

      const highlights = []
      for (const need of needs) {
        highlights.push({
          note_type: 'need',
          start_time: need.start_time,
          end_time: need.end_time,
          color: cnt.notesColors.needs,
          tooltip: <NeedTooltip id={need.id} content={need.content} startTime={need.start_time} participantId={need.participant_id} whoSaidIt={need.who_said_it} />
        })
      }
      for (const feedback of feedbacks) {
        highlights.push({
          note_type: 'feedback',
          start_time: feedback.start_time,
          end_time: feedback.end_time,
          color: feedback.sentiment_analysis === 'positive' ? cnt.notesColors.feedback.positive : feedback.sentiment_analysis === 'negative' ? cnt.notesColors.feedback.negative : cnt.notesColors.feedback.neutral,
          tooltip: <FeedbackTooltip id={feedback.id} content={feedback.content} startTime={feedback.start_time} participantId={feedback.participant_id} whoSaidIt={feedback.who_said_it} sentimentAnalysis={feedback.sentiment_analysis} />
        })
      }
      for (const quote of quotes) {
        highlights.push({
          note_type: 'quote',
          start_time: quote.start_time,
          end_time: quote.end_time,
          color: cnt.notesColors.quotes,
          tooltip: <QuoteTooltip id={quote.id} originalTranscriptSegment={quote.original_transcript_segment} startTime={quote.start_time} participantId={quote.participant_id} whoSaidIt={quote.who_said_it} />
        })
      }
      for (const qa of qas) {
        highlights.push({
          note_type: 'qa',
          start_time: qa.start_time,
          end_time: qa.end_time,
          color: cnt.notesColors.qas,
          tooltip: <QATooltip id={qa.id} content={qa.content} facilitatorQuestion={qa.facilitator_question} startTime={qa.start_time} participantId={qa.participant_id} whoSaidIt={qa.who_said_it} />
        })
      }
      highlights.sort((a, b) => a.start_time - b.start_time)
      if (highlights.length) {
        let highlightIndex = 0
        let transcriptIndex = 0
        while (highlightIndex < highlights.length && transcriptIndex < splittedTranscripts.length) {
          const transcript = splittedTranscripts[transcriptIndex]
          let sentenceIndex = 0
          while (sentenceIndex < transcript.sentences.length) {
            const sentence = transcript.sentences[sentenceIndex]
            while (highlightIndex < highlights.length && highlights[highlightIndex].end_time < sentence.start) {
              highlightIndex++
            }
            if (highlightIndex === highlights.length) break
            if (highlights[highlightIndex].start_time <= sentence.start && highlights[highlightIndex].end_time >= sentence.start) {
              transcript.sentences[sentenceIndex].highlight = highlights[highlightIndex]
            }
            sentenceIndex++
          }
          if (highlightIndex === highlights.length) break
          transcriptIndex++
        }
      }

      const newTranscripts = []
      for (const transcript of splittedTranscripts) {
        // const participant = participants[transcript.participantId]
        let hasHighlights = false
        const joinedSentences = []
        for (const sentence of transcript.sentences) {
          if (!joinedSentences.length) {
            joinedSentences.push(sentence)
            hasHighlights = !!sentence.highlight
            continue
          }
          if ((hasHighlights && sentence.highlight) || (!hasHighlights && !sentence.highlight)) {
            joinedSentences[joinedSentences.length - 1].text += ' ' + sentence.text
          } else {
            hasHighlights = !!sentence.highlight
            joinedSentences.push(sentence)
          }
        }
        newTranscripts.push({ ...transcript, sentences: joinedSentences })
      }

      setTranscripts(newTranscripts)
    }

    setMeetingLoading(false)
  }

  useEffect(() => {
    if (!param.meetingId) return navigate('/dashboard/meetings')
    if (typeof user !== 'object' || typeof user.workspaces === 'undefined') return
    initialData()
  }, [user])

  // ----- SCROLLING ------
  const [scrollToMenu, setScrollToMenu] = useState('overview')
  const [scrollTopContainer, setScrollTopContainer] = useState(null)
  const overviewRef = useRef(null)
  const quotesRef = useRef(null)
  const problemsRef = useRef(null)
  const needsRef = useRef(null)
  const qasRef = useRef(null)
  const feedbackRef = useRef(null)

  const setContainerRef = useCallback((node) => {
    if (node !== null) {
      setScrollTopContainer(node)
    }
  }, [setScrollTopContainer])

  useEffect(() => {
    // scroll to ref
    if (scrollTopContainer && scrollToMenu) {
      let element
      if (scrollToMenu === 'overview') {
        element = overviewRef.current
      } else if (scrollToMenu === 'quotes') {
        element = quotesRef.current
      } else if (scrollToMenu === 'problems') {
        element = problemsRef.current
      } else if (scrollToMenu === 'needs') {
        element = needsRef.current
      } else if (scrollToMenu === 'qas') {
        element = qasRef.current
      } else if (scrollToMenu === 'feedbacks') {
        element = feedbackRef.current
      }
      scrollTopContainer.scrollTo({
        top: element.offsetTop - 20,
        behavior: 'smooth'
      })
    }
  }, [scrollToMenu])

  const [scrollToQuote, setScrollToQuote] = useState('')
  const [scrollToNeed, setScrollToNeed] = useState('')
  const [scrollToQA, setScrollToQA] = useState('')
  const [scrollToFeedback, setScrollToFeedback] = useState('')

  const [scrollQuotesContainer, setScrollQuotesContainer] = useState(null)
  const setQuotesContainerRef = useCallback((node) => {
    if (node !== null) {
      setScrollQuotesContainer(node)
    }
  }, [setScrollQuotesContainer])

  // ----- DEBOUNCING ------
  const debouncedMeetingName = useDebounce(meeting.name, 500)

  useEffect(() => {
    if (!meeting.name) return
    async function updateProject () {
      console.log('updating meeting name', meeting.name, meeting.id)
      await customFetch('/updateMeetingName', 'PUT', { meetingId: meeting.id, newValue: meeting.name })
    }
    updateProject()
  }, [debouncedMeetingName])

  const [newParticipant, setNewParticipant] = useState('')
  const [newParticipantId, setNewParticipantId] = useState('')
  const debouncedParticipantName = useDebounce(newParticipant, 500)

  function handleRenameParticipant (newName, participantId) {
    setNewParticipant(newName)
    setNewParticipantId(participantId)
    let newParticipants
    try {
      newParticipants = JSON.parse(JSON.stringify(participants))
    } catch (error) {
      console.error('Error renaming participant:', error)
    }
    newParticipants[participantId].name = newName
    setParticipants(newParticipants)
  }

  useEffect(() => {
    if (newParticipant === '' || newParticipantId === '') return
    async function updateNotesCategory () {
      await customFetch('/renameParticipant', 'PUT', { name: newParticipant, id: newParticipantId })
    }
    updateNotesCategory()
  }, [debouncedParticipantName])

  return (
    <MeetingsContext.Provider value={{
      meetingLoading,
      meeting,
      setMeeting,
      meetingOverview,
      project,
      quotes,
      qas,
      feedbacks,
      needs,
      participants,
      transcripts,
      handleRenameParticipant,
      setContainerRef,
      scrollTopContainer,
      scrollToMenu,
      setScrollToMenu,
      overviewRef,
      quotesRef,
      problemsRef,
      needsRef,
      qasRef,
      feedbackRef,
      scrollToQuote,
      setScrollToQuote,
      scrollToNeed,
      setScrollToNeed,
      scrollToQA,
      setScrollToQA,
      scrollToFeedback,
      setScrollToFeedback,
      setQuotesContainerRef,
      scrollQuotesContainer
    }}>
      {children}
    </MeetingsContext.Provider>
  )
}

MeetingsContextProvider.propTypes = {
  children: PropTypes.node
}

import React, { useContext } from 'react'
import { MeetingsContext } from '../../../../context/MeetingContext'
import Tippy from '@tippyjs/react'
import PropTypes from 'prop-types'
import { FeedbackTooltip } from '../../tooltips/feedbackTooltip'

export function TimelineFeedbacks ({ duration }) {
  const { feedbacks } = useContext(MeetingsContext)

  return (
    <div className='w-full flex items-center gap-[10px]'>
        <div className='flex w-[100px] items-center gap-[5px]'>
            <p className="text-[#3f424a] text-xs font-extrabold font-['Manrope'] uppercase leading-3 tracking-wide">Feedback</p>
            <Tippy content='Product feedback: Positive, negative, and neutral.' placement='top'>
                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.25">
                    <path d="M5.48917 9.83185C7.96847 9.83185 9.97834 7.82198 9.97834 5.34268C9.97834 2.86338 7.96847 0.853516 5.48917 0.853516C3.00987 0.853516 1 2.86338 1 5.34268C1 7.82198 3.00987 9.83185 5.48917 9.83185Z" stroke="black" strokeWidth="1.43148" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M5.48926 7.83789V7.84346" stroke="black" strokeWidth="1.43148" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M5.48734 6.09232C5.47816 5.9304 5.52183 5.76988 5.6118 5.63493C5.70176 5.49999 5.83314 5.39793 5.98614 5.34413C6.17362 5.27243 6.3419 5.15819 6.47773 5.01041C6.61357 4.86263 6.71324 4.68533 6.76891 4.49248C6.82458 4.29963 6.83472 4.09649 6.79854 3.89905C6.76236 3.70161 6.68084 3.51527 6.56041 3.35469C6.43997 3.19411 6.28391 3.06367 6.1045 2.97366C5.92509 2.88364 5.72723 2.8365 5.52651 2.83594C5.32578 2.83539 5.12767 2.88143 4.94776 2.97045C4.76786 3.05947 4.61107 3.18903 4.48975 3.34894" stroke="black" strokeWidth="1.43148" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                </svg>
            </Tippy>
        </div>

        <div className='relative w-full border-b border-b-[#E4E4E4]'>
            {feedbacks.map(feedback => (
                <div key={feedback.id}>
                    <Tippy
                        interactive={true}
                        placement='top'
                        maxWidth={350}
                        content={<FeedbackTooltip id={feedback.id} content={feedback.content} participantId={feedback.participant_id} whoSaidIt={feedback.who_said_it} startTime={feedback.start_time} sentimentAnalysis={feedback.sentiment_analysis} />}
                    >
                        <div
                            style={{ left: `${(feedback.start_time * 100 / duration).toFixed(2)}%` }}
                            className={`absolute top-[-4px] w-[8.68px] h-[8.68px] rounded-full border ${feedback.sentiment_analysis === 'positive' ? 'bg-[#1FB912] border-[#259A1A]' : feedback.sentiment_analysis === 'negative' ? 'bg-[#D40814] border-[#A5111A]' : 'bg-[#FFF53C] border-[#DAD133]'}`}
                        ></div>
                    </Tippy>
                </div>
            ))}
        </div>
    </div>
  )
}

TimelineFeedbacks.propTypes = {
  duration: PropTypes.number
}

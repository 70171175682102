/* eslint-disable react/no-unknown-property */
import React, { useContext, useEffect, useState } from 'react'
import { DashboardContext } from '../../../context/DashboardContext'
import { EventsWrap } from '../events/eventsWrap'
import { getDownloadURL, ref } from 'firebase/storage'
import { storage } from '../../../firebase'
import { MeetingsFilters } from './filters'
import { Meeting } from './meeting'

export function Meetings () {
  const { meetings, meetingsOverview, allProjects } = useContext(DashboardContext)
  const [sessionsLoading, setSessionsLoading] = useState(true)
  const [allSessions, setAllSessions] = useState([]) // sessions are meetings with files
  const [sessions, setSessions] = useState([])
  const [projectFilter, setProjectFilter] = useState(-1)
  const [validationFilter, setValidationFilter] = useState('')

  function handleProjectFilter (projectIndex) {
    setProjectFilter(projectIndex)
    if (projectIndex === -1) {
      setSessions(allSessions)
    } else {
      const projectId = allProjects[projectIndex].id
      setSessions(allSessions.filter(meeting => meeting.project_id === projectId))
    }
  }

  function handleValidationFilter (isProspectToUseIt) {
    setValidationFilter(isProspectToUseIt)
    if (isProspectToUseIt === '') {
      setSessions(allSessions)
    } else {
      const filteredSessions = allSessions.filter(meeting => meetingsOverview[meeting.id]?.validation?.is_prospect_to_use_it === isProspectToUseIt)
      setSessions(filteredSessions)
    }
  }

  useEffect(() => {
    if (meetings.length === 0 || allProjects.length === 0) return

    const setSessionsInfo = async (files) => {
      try {
        const sessionsInfo = []
        for (const file of files) {
          // set project name
          file.project_name = allProjects.find(project => project.id === file.project_id).name
          // set thumbnail, If no thumbnail will use default image
          if (file.thumbnail) {
            const storageRef = ref(storage, `thumbs/${file.thumbnail}`)
            const url = await getDownloadURL(storageRef)
            file.thumbnail = url
          }
          // set background
          file.background = meetingsOverview[file.id]?.background
          sessionsInfo.push(file)
        }
        setAllSessions(sessionsInfo)
        setSessions(sessionsInfo)
        setSessionsLoading(false)
      } catch (error) {
        console.error('Error getting files URLs:', error)
      }
    }

    const meetingsWithFiles = meetings.filter(meet => meet.stored_name)
    setSessionsInfo(meetingsWithFiles)
  }, [meetings, allProjects])

  return (
    <div className='pr-[28px]'>
      <p className="pt-8 text-[#3f424a] text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">Meetings</p>

      <EventsWrap />

      <div className='w-full mb-[15px] flex justify-between items-center'>
        <p className="text-[#62708d] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">Recent Meetings</p>
        <MeetingsFilters projects={allProjects} projectIndex={projectFilter} setProjectIndex={handleProjectFilter} validation={validationFilter} setValidation={handleValidationFilter} />
      </div>

      {sessionsLoading && (
        <div className='flex justify-center items-center'>
          <svg className='w-40 h-40' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stopColor="#FF156D"></stop><stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop><stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop><stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop><stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a1)" strokeWidth="15" strokeLinecap="round" strokeDasharray="200 1000" strokeDashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#FF156D" strokeWidth="15" strokeLinecap="round" cx="100" cy="100" r="70"></circle></svg>
        </div>
      )}
      {!sessionsLoading && (
        <div className="flex flex-wrap gap-4">
          {sessions.map(meeting => <Meeting key={meeting.id} meeting={meeting} validation={meetingsOverview[meeting.id]?.validation?.is_prospect_to_use_it} />)}
        </div>
      )}
    </div>
  )
}

import React, { useState } from 'react'
import { customFetch } from '../../../utils'
import toast from 'react-hot-toast'
import PropTypes from 'prop-types'

export function AddMember ({ setUsers, handleClose }) {
  const [email, setEmail] = useState('')

  async function inviteMembers () {
    if (email.length === 0) return toast.error('Please enter an email address')
    // verify valid emails
    const emails = email.split(',').map(e => e.trim()).filter(e => e.length > 0)
    for (const e of emails) {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      if (!emailPattern.test(e)) return toast.error('Invalid email address: ' + e)
    }
    try {
      handleClose()
      setEmail('')
      const response = await customFetch('/inviteMembers', 'POST', { emails })
      if (response.users.length) setUsers(prevUsers => [...response.users, ...prevUsers])
    } catch (error) {
      console.error('error inviting member', error)
    }
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-opacity-30 bg-black" onClick={handleClose}>
      <div className="absolute w-[582px] h-[155px] p-[28px] bg-white rounded-2xl" style={{ left: 'calc(50% - 291px)', top: 'calc(50% - 80px)' }} onClick={(e) => e.stopPropagation()}>
        <header className='w-full flex items-center justify-between'>
          <p className="text-[#40424A] text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">Add Members to Workspace</p>
          <button onClick={handleClose}>
            <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.8577 12L13.7158 24" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M13.7158 12L26.8577 24" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </header>
        <div className='w-full mt-[20px] flex items-center justify-between'>
          <input
            className="grow pl-4 pr-8 py-2.5 shadow-inner border border-grey-200 bg-grey-100 rounded text-zinc text-base font-medium font-['Manrope']"
            placeholder="Emails, separated by comas"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && inviteMembers()}
          />
          <button className="ml-[10px] w-[75px] h-10 px-4 py-2.5 bg-[#FF4A8E] rounded shadow-inner justify-center items-center gap-3 inline-flex" onClick={inviteMembers}>
            <p className="text-white text-base font-semibold font-['Manrope'] leading-tight">Invite</p>
          </button>
        </div>
      </div>
    </div>
  )
}

AddMember.propTypes = {
  setUsers: PropTypes.func,
  handleClose: PropTypes.func
}

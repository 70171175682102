import React, { useContext } from 'react'
import { MeetingsContext } from '../../../context/MeetingContext'
import { formatDateHMMSS } from '../../../utils/formatDate'
import PropTypes from 'prop-types'

export function QuoteTooltip ({ id, originalTranscriptSegment, whoSaidIt, participantId, startTime }) {
  const { setScrollToQuote, participants } = useContext(MeetingsContext)

  return (
    <div className='p-2 w-[300px] text-left flex flex-col gap-[6px]'>
        <p className="font-bold text-[16px] font-['Manrope']">Quote</p>
        <p className="font-normal text-[14px] font-['Manrope']">{originalTranscriptSegment}</p>
        <div className='flex items-center'>
            <p className="font-semibold text-[14px] font-['Manrope']">{participantId ? participants[participantId]?.name : whoSaidIt} @ {formatDateHMMSS(startTime)}・</p>
            <button className="text-linkBlue font-semibold text-[14px] font-['Manrope']" onClick={() => setScrollToQuote(id)}>See Quote ❯</button>
        </div>
    </div>
  )
}

QuoteTooltip.propTypes = {
  id: PropTypes.string,
  originalTranscriptSegment: PropTypes.string,
  whoSaidIt: PropTypes.string,
  participantId: PropTypes.string,
  startTime: PropTypes.number
}

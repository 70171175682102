const cnt = {
  min_time_needed: 0 * 60, // 0 minutes
  default_notes_color: '#FFC921',
  colors: {
    grey: '#AFBCCF',
    red: '#FF4A8E',
    orange: '#FEA783',
    rind: '#FFC921',
    yellow: '#FFF53C',
    green: '#D1EC26',
    cyan: '#96D7D5',
    blue: '#34AED7',
    purple: '#B360D4',
    white: '#E6E6E6'
  },
  methods: {
    user_interviews: 'User Interviews',
    user_testing: 'User Testing',
    focus_group: 'Focus Group',
    mixed_methods: 'Mixed Methods'
  },
  notesColors: {
    quotes: '#34AED780', // rgba(52, 174, 215, 0.50)
    needs: '#FFC92180', // rgba(255, 201, 33, 0.50)
    qas: '#40424A99', // rgba(63.84, 66.47, 74.37, 0.60)
    feedback: {
      positive: '#1FB91266', // rgba(31.37, 185.45, 17.97, 0.40)
      negative: '#D4081466', // rgba(212.39, 7.92, 20.18, 0.40)
      neutral: '#FFF53C80' // rgba(255, 245, 60, 0.50)
    }
  }
}

export default cnt

import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from 'firebase/firestore'
import { db } from '../firebase'
import { EventsContext } from './EventsContext'
import { customFetch } from '../utils'
import { useDebounce } from '../hooks/useDebounce'
import toast from 'react-hot-toast'
import { AuthContext } from './AuthContext'

export const ProjectContext = createContext()

export const ProjectContextProvider = ({ children }) => {
  const param = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [listenRealTime, setListenRealTime] = useState(false)
  const [categories, setCategories] = useState([])
  const [transcripts, setTranscripts] = useState([])
  const [participants, setParticipants] = useState({})
  const [notes, setNotes] = useState([])
  const [newNoteId, setNewNoteId] = useState('')
  const [groupNotesBy, setGroupNotesBy] = useState('meeting')
  const [menu, setMenu] = useState('notes')
  const [scrollToCategory, setScrollToCategory] = useState('')
  const [showDuplicates, setShowDuplicates] = useState(true)
  const [scrollToMeeting, setScrollToMeeting] = useState(null)
  const [scrollToEnd, setScrollToEnd] = useState(null)
  const [scrollTopContainer, setScrollTopContainer] = useState(null)
  const [sidebarScrollContainer, setSidebarScrollContainer] = useState(null)
  const [notesInputRef, setNotesInputRef] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedMeeting, setSelectedMeeting] = useState(null)
  const [selectedNotes, setSelectedNotes] = useState([])
  const [noteHover, setNoteHover] = useState(null)
  const [firstNoteSelected, setFirstNoteSelected] = useState(null)
  const [showDeleteNotesDialog, setShowDeleteNotesDialog] = useState(false)
  const [meetings, setMeetings] = useState([])
  const [overview, setOverview] = useState({})
  const [validations, setValidations] = useState([])
  const [project, setProject] = useState({
    name: '',
    description: '',
    notes_format: 'HMW'
  })
  const [workspace, setWorkspace] = useState({})
  const [newCategory, setNewCategory] = useState([])
  const [categoryCreated, setCategoryCreated] = useState('')
  const debouncedCategoryName = useDebounce(newCategory, 500)
  const [meetingChange, setMeetingChange] = useState({})
  const debouncedMeetingChange = useDebounce(meetingChange, 500)
  const undoNotesDeletion = useRef(false)
  const { finishProcessingMeeting, setFinishProcessingMeeting, finishProcessingOverview, setFinishProcessingOverview } = useContext(EventsContext)
  const { user } = useContext(AuthContext)

  useEffect(() => {
    if (project.id === finishProcessingMeeting.projectId) {
      setFinishProcessingMeeting({ projectId: '', isLastFile: false })
      setIsLoading(true)
      setInitialData()
      if (finishProcessingMeeting.isLastFile && notes.length >= 30) {
        setGroupNotesBy('category')
      }
    }
  }, [finishProcessingMeeting])

  useEffect(() => {
    if (project.id === finishProcessingOverview.projectId) {
      setFinishProcessingOverview({ projectId: '' })
      setIsLoading(true)
      setInitialData()
    }
  }, [finishProcessingOverview])

  useEffect(() => {
    // listen to notes in real time
    if (!listenRealTime) return
    if (!project.id || isLoading) return
    const q = query(collection(db, 'needs'), where('project_id', '==', project.id))
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        // check if note already exists before adding
        let isNew = true
        for (const note of notes) {
          if (change.doc.id === note.id) {
            isNew = false
            break
          }
        }

        if (change.type === 'added' && isNew) {
          setNotes(prevNotes => prevNotes.concat({ ...change.doc.data(), id: change.doc.id }))
          setNewNoteId(change.doc.id)
          if (change.doc.data().category_id) {
            setCategories(prevCats => prevCats.map(cat => cat.id === change.doc.data().category_id ? { ...cat, amount: cat.amount + 1 } : cat))
          } else {
            if (categories.filter(cat => cat.content === 'Uncategorized').length) {
              setCategories(prevCats => prevCats.map(cat => cat.content === 'Uncategorized' ? { ...cat, amount: cat.amount + 1 } : cat))
            } else {
              setCategories(prevCats => [{ id: 'Uncategorized', content: 'Uncategorized', amount: 1 }, ...prevCats])
            }
          }
        }
      })
    })
    return unsubscribe
  }, [listenRealTime, project])

  useEffect(() => {
    // listen to transcripts in real time
    if (!listenRealTime) return
    if (meetings.length === 0 || isLoading) return

    // Array to store all unsubscribe functions
    const unsubscribeFns = []

    for (let i = 0; i < meetings.length; i += 10) {
      const q = query(collection(db, 'transcripts'), where('meeting_id', 'in', meetings.slice(i, i + 10).map(meet => meet.id)))
      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          let isNew = true
          for (const transcript of transcripts) {
            if (change.doc.id === transcript.id) {
              isNew = false
              break
            }
          }
          if (change.type === 'added' && isNew) {
            setTranscripts(prevTranscripts => prevTranscripts.concat({ ...change.doc.data(), id: change.doc.id }))
          }
        })
      })

      unsubscribeFns.push(unsubscribe)
    }

    return () => {
      unsubscribeFns.forEach(unsub => unsub())
      console.log('unsubscribed from transcripts!')
    }
  }, [listenRealTime, meetings])

  useEffect(() => {
    // listen to participants in real time
    if (!listenRealTime) return
    if (meetings.length === 0 || isLoading) return

    // Array to store all unsubscribe functions
    const unsubscribeFns = []

    for (let i = 0; i < meetings.length; i += 10) {
      const q = query(collection(db, 'participants'), where('meeting_id', 'in', meetings.slice(i, i + 10).map(meet => meet.id)))
      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          // check if transcript already exists before adding
          let isNew = true
          if (participants[change.doc.id] !== undefined) {
            isNew = false
          }

          if (change.type === 'added' && isNew) {
            setParticipants(prevParticipants => ({ ...prevParticipants, [change.doc.id]: change.doc.data().name }))
          }
        })
      })

      unsubscribeFns.push(unsubscribe)
    }

    return () => {
      unsubscribeFns.forEach(unsub => unsub())
      console.log('unsubscribed from participants!')
    }
    // return unsubscribe
  }, [listenRealTime, meetings])

  useEffect(() => {
    // listen to meetings in real time (only isLive field)
    if (!listenRealTime) return
    if (meetings.length === 0 || isLoading) return
    const q = query(collection(db, 'meetings'), where('project_id', '==', project.id))
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'modified') {
          const index = meetings.findIndex(meet => meet.id === change.doc.id)
          if (index !== -1) {
            if (change.doc.data().isLive && !meetings[index].isLive) {
              setMeetings(prevMeets => prevMeets.map(meet => (meet.id === change.doc.id) ? { ...meet, isLive: true } : meet))
            }
            if (!change.doc.data().isLive && meetings[index].isLive) {
              setMeetings(prevMeets => prevMeets.map(meet => (meet.id === change.doc.id) ? { ...meet, isLive: undefined } : meet))
            }
          }
        }
      })
    })
    return unsubscribe
  }, [listenRealTime, meetings])

  useEffect(() => {
    // listen to workspace in real time (only additional_amount and sub_hours_left fields)
    if (!listenRealTime || isLoading || !user.active_workspace) return

    const unsubscribe = onSnapshot(doc(db, 'workspaces', user.active_workspace), (doc) => {
      if (doc.exists) {
        if (doc.data().additional_amount !== workspace.additional_amount || doc.data().sub_hours_left !== workspace.sub_hours_left) {
          setWorkspace(prevWorkspace => ({ ...prevWorkspace, additional_amount: doc.data().additional_amount, sub_hours_left: doc.data().sub_hours_left }))
        }
      } else {
        console.error('Workspace does not exists')
      }
    })

    return () => unsubscribe()
  }, [listenRealTime, user.active_workspace])

  useEffect(() => {
    // listen to overview in real time
    if (!listenRealTime || isLoading || !user.active_workspace) return
    if (meetings.length === 0 || isLoading) return

    const unsubscribe = onSnapshot(doc(db, 'overviews', project.id), (doc) => {
      if (doc.exists) {
        setOverview({ ...doc.data(), id: doc.id })
      } else {
        console.error('Overview does not exists')
      }
    })

    return () => unsubscribe()
  }, [listenRealTime, user.active_workspace])

  useEffect(() => {
    if (!meetings.length) return

    async function getValidations () {
      const newValidations = []
      for (let i = 0; i < meetings.length; i += 10) {
        const q = query(collection(db, 'meetingsOverview'), where('__name__', 'in', meetings.slice(i, i + 10).map(meet => meet.id)))
        const querySnapshot = await getDocs(q)
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            newValidations.push(doc.data().validation)
          })
        }
      }
      setValidations(newValidations)
    }
    getValidations()
  }, [meetings])

  useEffect(() => {
    // handle browser visibility change (like interruptions, tab changes, etc.)
    // needs to be tested
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        console.log('visibility changed to visible')
        setListenRealTime(true)
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      console.log('visibility changed to invisible')
      setListenRealTime(false)
    }
  }, [])

  async function setInitialData () {
    try {
      // get project data
      const projRef = doc(db, 'projects', param.id)
      const projSnap = await getDoc(projRef)

      if (!projSnap.exists()) {
        toast.error('No documents with that project id!')
        navigate('/dashboard/home')
        return
      }
      if (!Object.keys(user.workspaces).includes(projSnap.data().workspace_id)) {
        toast.error('You do not have access to this project')
        navigate('/dashboard/home')
        return
      }

      setProject({ ...projSnap.data(), id: projSnap.id })

      const overviewRef = doc(db, 'overviews', param.id)
      const overviewSnap = await getDoc(overviewRef)
      if (overviewSnap.exists()) {
        setOverview({ ...overviewSnap.data(), id: overviewSnap.id })
        if (overviewSnap.data().background && overviewSnap.data().quotes.length && overviewSnap.data().feedbacks.length && overviewSnap.data().problems.length && overviewSnap.data().qas.length) {
          setMenu('overview')
        }
      }

      const workspaceRef = doc(db, 'workspaces', projSnap.data().workspace_id)
      const workspaceSnap = await getDoc(workspaceRef)
      if (!workspaceSnap.exists()) {
        toast.error('No documents with that project id!')
        navigate('/dashboard/home')
        return
      }
      setWorkspace({ ...workspaceSnap.data(), id: workspaceSnap.id })

      // get meetings data
      let q = query(collection(db, 'meetings'), where('project_id', '==', projSnap.id))
      let querySnapshot = await getDocs(q)
      if (querySnapshot.empty) return

      const newMeets = []
      querySnapshot.forEach((doc) => {
        newMeets.push({ ...doc.data(), id: doc.id })
      })
      newMeets.sort((a, b) => {
        let timeA = a.created_at
        let timeB = b.created_at
        if (a.start) {
          timeA = new Date(a.start).getTime()
        }
        if (b.start) {
          timeB = new Date(b.start).getTime()
        }
        return (timeA < timeB) ? 1 : -1
      })
      setMeetings(newMeets)
      if (newMeets.length) {
        setSelectedMeeting(newMeets.length && newMeets[0].id)
      }

      // transcripts are setted in real time

      // get notes data
      q = query(collection(db, 'needs'), where('project_id', '==', param.id))
      querySnapshot = await getDocs(q)
      const newNotes = []
      querySnapshot.forEach((doc) => {
        newNotes.push({ ...doc.data(), id: doc.id })
      })
      newNotes.sort((a, b) => (a.created_at > b.created_at) ? 1 : -1)
      setNotes(newNotes)

      // get categories data
      q = query(collection(db, 'categories'), where('project_id', '==', projSnap.id))
      querySnapshot = await getDocs(q)

      const newCategories = []
      let uncategorizedAmount = newNotes.length
      if (!querySnapshot.empty) {
        setGroupNotesBy('category')
        querySnapshot.forEach((doc) => {
          const amount = newNotes.filter(note => note.category_id === doc.id).length
          uncategorizedAmount -= amount
          newCategories.push({ ...doc.data(), id: doc.id, amount })
        })
      }
      newCategories.push({ id: 'Uncategorized', content: 'Uncategorized', amount: uncategorizedAmount })

      // Uncategorized should go first, Misc should go last, and the others ordered by amount
      newCategories.sort((a, b) => {
        if (a.content === 'Uncategorized') {
          return -1
        } else if (b.content === 'Uncategorized') {
          return 1
        } else if (a.content === 'Misc') {
          return 1
        } else if (b.content === 'Misc') {
          return -1
        } else if (a.amount > b.amount) {
          return -1
        } else if (a.amount === b.amount) {
          return (a.content > b.content) ? 1 : -1
        } else {
          return 1
        }
      })
      setCategories(newCategories)

      setListenRealTime(true)
    } catch (err) {
      console.log('error initiating project!', err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (typeof user !== 'object' || typeof user.workspaces === 'undefined') return

    setInitialData()
  }, [user])

  async function handleDeleteCategory (category) {
    if (confirm('Are you sure you want to delete the category?')) {
      let newCategories = categories.filter(cat => cat.id !== category.id)
      newCategories = newCategories.map(cat => cat.content === 'Uncategorized' ? { ...cat, amount: cat.amount + category.amount } : cat)
      setCategories(newCategories)
      const newNotes = notes.map(note => note.category_id === category.id ? { ...note, category_id: undefined } : note)
      setNotes(newNotes)
      await customFetch('/deleteCategory', 'DELETE', { categoryId: category.id, projectId: category.project_id })
    }
  }

  function handleRenameCategory (newValue, category) {
    setNewCategory(newValue)
    setCategories(prevCategories => prevCategories.map(cat => cat.id === category.id ? { ...cat, content: newValue } : cat))
  }

  useEffect(() => {
    if (newCategory === '') return
    const category = categories.filter(cat => cat.content === newCategory)
    if (category.length === 0) return
    async function updateNotesCategory () {
      await customFetch('/renameCategory', 'PUT', { content: newCategory, categoryId: category[0].id })
    }
    updateNotesCategory()
  }, [debouncedCategoryName])

  useEffect(() => {
    if (!meetingChange || Object.keys(meetingChange).length === 0) return
    async function updateMeetingName () {
      const { meetingId, newValue } = meetingChange
      await customFetch('/updateMeetingName', 'PUT', { meetingId, newValue })
    }
    updateMeetingName()
  }, [debouncedMeetingChange])

  function handleRenameMeeting (newValue, meetingId) {
    setMeetingChange({ newValue, meetingId })
    setMeetings(prevMeetings => prevMeetings.map(meeting => meeting.id === meetingId ? { ...meeting, name: newValue } : meeting))
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        if (document.activeElement &&
          (document.activeElement.tagName === 'INPUT' ||
          document.activeElement.tagName === 'TEXTAREA' ||
          document.activeElement.isContentEditable)) {
          return
        }

        prepareDeleteNotes()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [selectedNotes])

  const prepareDeleteNotes = async () => {
    if (selectedNotes.length === 1) {
      const prevNotes = [...notes]
      const prevCategories = [...categories]
      setNotes(prevNotes => prevNotes.filter(n => n.id !== selectedNotes[0]))
      const newCategories = JSON.parse(JSON.stringify(categories))
      const note = notes.find(n => n.id === selectedNotes[0])
      const noteCat = note.category_id ? note.category_id : 'Uncategorized'
      const catIndex = newCategories.findIndex(cat => cat.id === noteCat)
      newCategories[catIndex].amount--
      setCategories(newCategories)

      toast.custom((t) => (
        <div style={{
          background: '#fff',
          color: '#363636',
          boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05)',
          padding: '16px 20px',
          borderRadius: '8px'
        }}>{selectedNotes.length} Notes Permanently Deleted!&nbsp;
          <button style={{ color: '#0052CC' }} onClick={() => {
            undoNotesDeletion.current = true
            setNotes(prevNotes)
            setCategories(prevCategories)
          }}>Undo</button>
        </div>
      ), { duration: 5000 })

      setTimeout(async () => {
        if (!undoNotesDeletion.current) await customFetch('/deleteNote', 'DELETE', { noteId: selectedNotes[0] })
        undoNotesDeletion.current = false
      }, 5000)
    } else {
      setShowDeleteNotesDialog(true)
    }
  }

  const handleDeleteNotes = async () => {
    try {
      const prevNotes = [...notes]
      const prevCategories = [...categories]
      const newCategories = JSON.parse(JSON.stringify(categories))
      selectedNotes.forEach(noteId => {
        const note = notes.find(n => n.id === noteId)
        const noteCat = note.category_id ? note.category_id : 'Uncategorized'
        const catIndex = newCategories.findIndex(cat => cat.id === noteCat)
        newCategories[catIndex].amount--
      })
      setCategories(newCategories)
      setNotes(prevNotes => prevNotes.filter(note => !selectedNotes.includes(note.id)))
      setSelectedNotes([])
      setFirstNoteSelected(null)
      setNoteHover(null)
      setShowDeleteNotesDialog(false)
      toast.custom((t) => (
        <div style={{
          background: '#fff',
          color: '#363636',
          boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05)',
          padding: '16px 20px',
          borderRadius: '8px'
        }}>{selectedNotes.length} Notes Permanently Deleted!&nbsp;
          <button style={{ color: '#0052CC' }} onClick={() => {
            undoNotesDeletion.current = true
            setNotes(prevNotes)
            setCategories(prevCategories)
          }}>Undo</button>
        </div>
      ), { duration: 5000 })

      setTimeout(async () => {
        if (!undoNotesDeletion.current) await customFetch('/deleteNotes', 'DELETE', { notes: selectedNotes, projectId: project.id })
        undoNotesDeletion.current = false
      }, 5000)
    } catch (error) {
      console.log(error)
      toast.error('Whoops! Something went wrong. Please try again.')
    }
  }

  return (
    <ProjectContext.Provider value={{
      categories,
      setCategories,
      newNoteId,
      notes,
      setNotes,
      transcripts,
      participants,
      project,
      setProject,
      meetings,
      setMeetings,
      selectedMeeting,
      setSelectedMeeting,
      selectedCategory,
      setSelectedCategory,
      groupNotesBy,
      setGroupNotesBy,
      isLoading,
      setIsLoading,
      menu,
      setMenu,
      setListenRealTime,
      categoryCreated,
      setCategoryCreated,
      handleDeleteCategory,
      handleRenameCategory,
      handleRenameMeeting,
      showDuplicates,
      setShowDuplicates,
      scrollToCategory,
      setScrollToCategory,
      scrollTopContainer,
      setScrollTopContainer,
      sidebarScrollContainer,
      setSidebarScrollContainer,
      scrollToEnd,
      setScrollToEnd,
      notesInputRef,
      setNotesInputRef,
      scrollToMeeting,
      setScrollToMeeting,
      prepareDeleteNotes,
      handleDeleteNotes,
      showDeleteNotesDialog,
      setShowDeleteNotesDialog,
      firstNoteSelected,
      setFirstNoteSelected,
      overview,
      validations,
      selectedNotes,
      setSelectedNotes,
      noteHover,
      setNoteHover,
      workspace
    }}>
      {children}
    </ProjectContext.Provider>
  )
}

ProjectContextProvider.propTypes = {
  children: PropTypes.node
}

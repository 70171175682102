import React, { useContext } from 'react'
import { GridByMeeting } from './groupByMeeting'
import { GridByCategory } from './groupByCategory'
import { ProjectContext } from '../../../context/ProjectContext'
import { createPortal } from 'react-dom'
import { DeleteNotesDialog } from './deleteNotesDialog'
import PropTypes from 'prop-types'

export function StickyGrid ({ groupNotesBy }) {
  const { showDeleteNotesDialog, setShowDeleteNotesDialog, selectedNotes, handleDeleteNotes } = useContext(ProjectContext)

  return (
    <>
        {groupNotesBy === 'meeting' ? <GridByMeeting /> : <GridByCategory />}
        {showDeleteNotesDialog && createPortal(
            <DeleteNotesDialog handleDeleteNotes={handleDeleteNotes} notesCount={selectedNotes.length} handleClose={() => setShowDeleteNotesDialog(false)} />,
            document.body
        )}
    </>
  )
}

StickyGrid.propTypes = {
  groupNotesBy: PropTypes.string
}

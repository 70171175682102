import React, { useContext, useEffect, useState } from 'react'
import { ProjectContext } from '../../../context/ProjectContext'
import { Problems } from './problems'
import { QAs } from './qas'
import { Validation } from './validation'
import { Interviews } from './interviews'
import { getDownloadURL, ref } from 'firebase/storage'
import { storage } from '../../../firebase'
import { Keyquotes } from './keyquotes'
import { Needs } from './needs'
import { Feedbacks } from './feedbacks'

export function Overview () {
  const { overview, meetings, validations } = useContext(ProjectContext)
  const [sessions, setSessions] = useState([]) // sessions are meetings with files

  useEffect(() => {
    if (meetings.length === 0) return

    const setSessionsInfo = async (files) => {
      try {
        const sessionsWithThumbnails = []
        for (const file of files) {
          // If no thumbnail will use default image
          if (!file.thumbnail) {
            sessionsWithThumbnails.push(file)
            continue
          }
          const storageRef = ref(storage, `thumbs/${file.thumbnail}`)
          const url = await getDownloadURL(storageRef)
          sessionsWithThumbnails.push({ ...file, thumbnail: url })
        }
        setSessions(sessionsWithThumbnails)
      } catch (error) {
        console.error('Error getting files URLs:', error)
      }
    }

    const meetingsWithFiles = meetings.filter(meet => meet.stored_name)
    setSessionsInfo(meetingsWithFiles)
  }, [meetings])

  return (
    <div className="w-[917px] px-[45px] pt-[24px] bg-white rounded-2xl flex-col justify-start items-center gap-9 inline-flex">
      <p className="w-full text-[#40424A] text-4xl font-normal font-['DM_Sans'] leading-[41.40px]">Overview</p>

      {overview.background && overview.background.length && (
        <div className='w-full'>
          <p className="mb-4 w-full pb-[10px] text-[#40424A] text-[26px] font-semibold font-['Manrope'] leading-[29.90px] border-b-2 border-grey-200">Background</p>
          <p className="text-[#40424A] text-lg font-normal font-['DM_Sans'] leading-[23px]">{overview.background}</p>
        </div>
      )}

      <Interviews sessions={sessions} />

      {Array.isArray(overview.problems) && overview.problems.length && (
        <Problems problems={overview.problems} />
      )}

      {Array.isArray(overview.quotes) && overview.quotes.length && (
        <Keyquotes keyquotes={overview.quotes} sessions={sessions} />
      )}

      <Needs />

      {Array.isArray(overview.qas) && overview.qas.length && (
        <QAs QAs={overview.qas} />
      )}

      {validations.length && (
        <Validation validations={validations} />
      )}

      {Array.isArray(overview.feedbacks) && overview.feedbacks.length && (
        <Feedbacks feedbacks={overview.feedbacks} />
      )}
    </div>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

export function Menu ({ menu, setMenu }) {
  return (
      <div className='ml-[32px]'>
        <button onClick={() => setMenu('home')} className='mt-16 flex items-center'>
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.22221 11.4999H1L10.9999 1.5L20.9999 11.4999H18.7777" stroke={menu !== 'home' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.22266 11.5V19.2777C3.22266 19.8671 3.45678 20.4323 3.87353 20.8491C4.29027 21.2658 4.8555 21.4999 5.44486 21.4999H16.5559C17.1453 21.4999 17.7105 21.2658 18.1272 20.8491C18.544 20.4323 18.7781 19.8671 18.7781 19.2777V11.5" stroke={menu !== 'home' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.66602 21.5002V14.8335C7.66602 14.2442 7.90014 13.6789 8.31689 13.2622C8.73363 12.8455 9.29886 12.6113 9.88822 12.6113H12.1104C12.6998 12.6113 13.265 12.8455 13.6818 13.2622C14.0985 13.6789 14.3326 14.2442 14.3326 14.8335V21.5002" stroke={menu !== 'home' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          {menu !== 'home'
            ? <p className="text-[#62718D] text-base font-medium font-['Manrope'] leading-[23px] mx-4">Home</p>
            : <p className="text-black text-base font-semibold font-['Manrope'] leading-tight mx-4">Home</p>}
        </button>

        <button onClick={() => setMenu('projects')} className='mt-6 flex items-center'>
          <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1H19V13.2727C19 13.4897 18.9138 13.6978 18.7604 13.8513C18.6069 14.0047 18.3988 14.0909 18.1818 14.0909H14.0909C13.8739 14.0909 13.6658 14.0047 13.5124 13.8513C13.3589 13.6978 13.2727 13.4897 13.2727 13.2727V10.8182H6.72727V16.5455C6.72727 16.7624 6.64107 16.9706 6.48763 17.124C6.33419 17.2774 6.12609 17.3636 5.90909 17.3636H1.81818C1.60119 17.3636 1.39308 17.2774 1.23964 17.124C1.0862 16.9706 1 16.7624 1 16.5455V1Z" stroke={menu !== 'projects' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.72727 7.54492H1" stroke={menu !== 'projects' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.72656 1V10.8182" stroke={menu !== 'projects' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.2734 7.54492H19.0007" stroke={menu !== 'projects' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.2734 1V10.8182" stroke={menu !== 'projects' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          {menu !== 'projects'
            ? <p className="text-[#62718D] text-base font-medium font-['Manrope'] leading-[23px] mx-4">Projects</p>
            : <p className="text-black text-base font-semibold font-['Manrope'] leading-tight mx-4">Projects</p>}
        </button>

        <button onClick={() => setMenu('meetings')} className='mt-6 flex items-center'>
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 8.00061L17.553 5.72461C17.7054 5.64844 17.8748 5.61249 18.045 5.62015C18.2152 5.62782 18.3806 5.67885 18.5256 5.76841C18.6706 5.85797 18.7902 5.98308 18.8733 6.13188C18.9563 6.28067 18.9999 6.44822 19 6.61861V13.3826C18.9999 13.553 18.9563 13.7205 18.8733 13.8693C18.7902 14.0181 18.6706 14.1432 18.5256 14.2328C18.3806 14.3224 18.2152 14.3734 18.045 14.3811C17.8748 14.3887 17.7054 14.3528 17.553 14.2766L13 12.0006V8.00061Z" stroke={menu !== 'meetings' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 6C1 5.46957 1.21071 4.96086 1.58579 4.58579C1.96086 4.21071 2.46957 4 3 4H11C11.5304 4 12.0391 4.21071 12.4142 4.58579C12.7893 4.96086 13 5.46957 13 6V14C13 14.5304 12.7893 15.0391 12.4142 15.4142C12.0391 15.7893 11.5304 16 11 16H3C2.46957 16 1.96086 15.7893 1.58579 15.4142C1.21071 15.0391 1 14.5304 1 14V6Z" stroke={menu !== 'meetings' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          {menu !== 'meetings'
            ? <p className="text-[#62718D] text-base font-medium font-['Manrope'] leading-[23px] mx-4">Meetings</p>
            : <p className="text-black text-base font-semibold font-['Manrope'] leading-tight mx-4">Meetings</p>}
        </button>

        <button onClick={() => setMenu('integrations')} className="mt-6 flex items-center">
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.23189 4L18.5006 14.2687L15.9331 16.8362C15.2638 17.5323 14.4623 18.088 13.5757 18.4706C12.689 18.8533 11.7349 19.0552 10.7693 19.0647C9.80359 19.0742 8.84574 18.891 7.95173 18.5258C7.05773 18.1606 6.24554 17.6208 5.56268 16.9379C4.87982 16.2551 4.34 15.4429 3.97482 14.5489C3.60964 13.6549 3.42642 12.697 3.4359 11.7314C3.44537 10.7657 3.64734 9.81161 4.02998 8.92494C4.41263 8.03828 4.96827 7.23683 5.6644 6.56749L8.23189 4Z" stroke={menu !== 'integrations' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 21.5L5.37499 17.125" stroke={menu !== 'integrations' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.75 1.5L10.375 5.87499" stroke={menu !== 'integrations' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21 7.75L16.625 12.125" stroke={menu !== 'integrations' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          {menu !== 'integrations'
            ? <p className="text-[#62718D] text-base font-medium font-['Manrope'] leading-[23px] mx-4">Integrations</p>
            : <p className="text-black text-base font-semibold font-['Manrope'] leading-tight mx-4">Integrations</p>}
        </button>

        <button onClick={() => setMenu('team')} className='mt-6 flex items-center'>
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2628_20999)">
            <path d="M4.16602 6.33333C4.16602 7.21739 4.5172 8.06523 5.14233 8.69036C5.76745 9.31548 6.61529 9.66667 7.49935 9.66667C8.3834 9.66667 9.23125 9.31548 9.85637 8.69036C10.4815 8.06523 10.8327 7.21739 10.8327 6.33333C10.8327 5.44928 10.4815 4.60143 9.85637 3.97631C9.23125 3.35119 8.3834 3 7.49935 3C6.61529 3 5.76745 3.35119 5.14233 3.97631C4.5172 4.60143 4.16602 5.44928 4.16602 6.33333Z" stroke={menu !== 'team' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.5 18V16.3333C2.5 15.4493 2.85119 14.6014 3.47631 13.9763C4.10143 13.3512 4.94928 13 5.83333 13H9.16667C10.0507 13 10.8986 13.3512 11.5237 13.9763C12.1488 14.6014 12.5 15.4493 12.5 16.3333V18" stroke={menu !== 'team' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.334 3.10742C14.051 3.29101 14.6865 3.70801 15.1403 4.29268C15.5942 4.87736 15.8405 5.59645 15.8405 6.33659C15.8405 7.07673 15.5942 7.79582 15.1403 8.3805C14.6865 8.96517 14.051 9.38217 13.334 9.56576" stroke={menu !== 'team' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.5 18V16.3333C17.4958 15.5976 17.2483 14.884 16.7961 14.3037C16.3439 13.7233 15.7124 13.3089 15 13.125" stroke={menu !== 'team' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_2628_20999">
            <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
            </defs>
          </svg>
          {menu !== 'team'
            ? <p className="text-[#62718D] text-base font-medium font-['Manrope'] leading-[23px] mx-4">Team</p>
            : <p className="text-black text-base font-semibold font-['Manrope'] leading-tight mx-4">Team</p>}
        </button>

        {/* <button onClick={() => setMenu('settings')} className="mt-6 flex items-centerZ">
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.13889 2.96333C9.61222 1.01222 12.3878 1.01222 12.8611 2.96333C12.9321 3.25644 13.0714 3.52864 13.2675 3.75778C13.4636 3.98691 13.711 4.1665 13.9896 4.28194C14.2682 4.39738 14.5702 4.44539 14.8709 4.42208C15.1716 4.39876 15.4625 4.30478 15.72 4.14778C17.4344 3.10333 19.3978 5.06556 18.3533 6.78111C18.1966 7.03851 18.1027 7.32926 18.0795 7.62975C18.0562 7.93023 18.1042 8.23197 18.2195 8.51042C18.3348 8.78888 18.5142 9.0362 18.743 9.23228C18.9719 9.42836 19.2438 9.56767 19.5367 9.63889C21.4878 10.1122 21.4878 12.8878 19.5367 13.3611C19.2436 13.4321 18.9714 13.5714 18.7422 13.7675C18.5131 13.9636 18.3335 14.211 18.2181 14.4896C18.1026 14.7682 18.0546 15.0702 18.0779 15.3709C18.1012 15.6716 18.1952 15.9625 18.3522 16.22C19.3967 17.9344 17.4344 19.8978 15.7189 18.8533C15.4615 18.6966 15.1707 18.6027 14.8703 18.5795C14.5698 18.5562 14.268 18.6042 13.9896 18.7195C13.7111 18.8348 13.4638 19.0142 13.2677 19.243C13.0716 19.4719 12.9323 19.7438 12.8611 20.0367C12.3878 21.9878 9.61222 21.9878 9.13889 20.0367C9.06787 19.7436 8.92864 19.4714 8.73254 19.2422C8.53644 19.0131 8.28901 18.8335 8.01039 18.7181C7.73176 18.6026 7.42982 18.5546 7.12913 18.5779C6.82844 18.6012 6.5375 18.6952 6.28 18.8522C4.56556 19.8967 2.60222 17.9344 3.64667 16.2189C3.80345 15.9615 3.89728 15.6707 3.92054 15.3703C3.9438 15.0698 3.89583 14.768 3.78052 14.4896C3.66522 14.2111 3.48584 13.9638 3.25697 13.7677C3.02809 13.5716 2.75618 13.4323 2.46333 13.3611C0.512222 12.8878 0.512222 10.1122 2.46333 9.63889C2.75644 9.56787 3.02864 9.42864 3.25778 9.23254C3.48691 9.03644 3.6665 8.78901 3.78194 8.51039C3.89738 8.23176 3.94539 7.92982 3.92208 7.62913C3.89876 7.32844 3.80478 7.0375 3.64778 6.78C2.60333 5.06556 4.56556 3.10222 6.28111 4.14667C7.39222 4.82222 8.83222 4.22444 9.13889 2.96333Z" stroke={menu !== 'settings' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.9993 14.8327C12.8403 14.8327 14.3327 13.3403 14.3327 11.4993C14.3327 9.6584 12.8403 8.16602 10.9993 8.16602C9.1584 8.16602 7.66602 9.6584 7.66602 11.4993C7.66602 13.3403 9.1584 14.8327 10.9993 14.8327Z" stroke={menu !== 'settings' ? '#62718D' : '#FF4A8E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          {menu !== 'settings'
            ? <p className="text-[#62718D] text-base font-medium font-['Manrope'] leading-[23px] mx-4">Settings</p>
            : <p className="text-black text-base font-semibold font-['Manrope'] leading-tight mx-4">Settings</p>}
        </button> */}
      </div>
  )
}

Menu.propTypes = {
  menu: PropTypes.string.isRequired,
  setMenu: PropTypes.func.isRequired
}

import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'

export function Callback () {
  const location = useLocation()
  const { sendMicrosoftCode } = useContext(AuthContext)
  const query = new URLSearchParams(location.search)

  useEffect(() => {
    if (query.get('code')) sendMicrosoftCode(query.get('code'))
  }, [])

  return (
    <>
    </>
  )
}

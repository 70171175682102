import React from 'react'
import PropTypes from 'prop-types'
import { WorkspaceSelection } from './workspaceSelection'
import { WorkspaceUsage } from './workspaceUsage'
import { Menu } from './menu'

export function Sidebar ({ menu, setMenu }) {
  return (
      <div className='mr-[20px]'>
        <div className='ml-[24px] mt-[26px]'>
          <svg width="124" height="15" viewBox="0 0 138 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_824_1675)">
            <path d="M42.8145 2.45508V10.0034C42.8145 13.7506 40.0378 16.2039 36.5871 16.2039C33.1634 16.2039 30.3867 13.7506 30.3867 10.0034V2.45508H32.5434V10.0034C32.5434 12.6723 34.3496 14.3168 36.5871 14.3168C38.8247 14.3168 40.6579 12.6723 40.6579 10.0034V2.45508H42.8145Z" fill="#2A334E"/>
            <path d="M44.6914 15.3143L45.2845 13.4002C46.5785 13.9125 47.9803 14.3168 49.3013 14.3168C50.784 14.3168 52.1858 13.6698 52.1858 12.1871C52.1858 11.1357 51.4849 10.4887 49.2743 9.92261C46.7402 9.27561 44.9071 8.11639 44.9071 6.0406C44.9071 3.47955 47.1716 2.18555 49.6787 2.18555C51.2153 2.18555 52.6711 2.61688 54.019 3.20997L53.4259 4.98922C52.2128 4.50397 50.9188 4.07264 49.6787 4.07264C48.3038 4.07264 47.0637 4.7466 47.0637 6.0406C47.0637 6.95718 47.7916 7.60419 49.7596 8.06248C52.5902 8.70948 54.3425 9.97652 54.3425 12.1871C54.3425 14.9369 51.9163 16.2039 49.3013 16.2039C47.7107 16.2039 46.1202 15.8535 44.6914 15.3143Z" fill="#2A334E"/>
            <path d="M61.0457 2.21869C64.4895 1.7347 66.8265 3.448 67.7334 6.99569L67.9586 8.59745L57.7874 10.0269C58.379 13.0745 60.3239 14.5162 63.5349 14.1194C64.9498 13.9205 66.2738 13.4622 67.3467 12.7669L68.0444 14.4384C66.7692 15.2438 65.3193 15.7742 63.7976 15.9881C59.633 16.5734 56.222 14.3849 55.6179 10.0868C55.1039 6.42945 57.2357 2.8086 61.0457 2.21869ZM61.3351 4.08366C59.146 4.39131 57.8953 6.14604 57.6849 8.13569L65.5335 7.03263C65.1191 4.85857 63.3639 3.79852 61.3351 4.08366Z" fill="#2A334E"/>
            <path d="M69.998 15.9613V2.45513H72.1547V3.9648L72.3165 4.04568C73.2331 2.96734 74.6888 2.18555 76.4411 2.18555H76.8185V4.07264H76.1715C74.5271 4.07264 73.1252 4.85443 72.1547 6.09452V15.9613H69.998Z" fill="#2A334E"/>
            <path d="M98.9142 7.84681V15.9343H96.7575V7.84681C96.7575 5.20489 95.4096 4.07264 93.7652 4.07264C92.1207 4.07264 90.5302 4.77355 89.6944 6.57977C89.7214 6.82239 89.7484 7.06502 89.7484 7.30764V15.9343H87.5917V7.30764C87.5917 5.04314 86.2438 4.07264 84.5993 4.07264C82.847 4.07264 81.2025 4.77355 80.3129 6.60672V15.9343H78.1562V2.45513H80.3129V4.15351L80.4747 4.20743C81.4182 3.02126 83.0627 2.18555 85.1385 2.18555C86.8369 2.18555 88.3465 2.9943 89.1283 4.53093C90.3145 3.07517 92.0398 2.18555 94.3043 2.18555C96.8654 2.18555 98.9142 4.09959 98.9142 7.84681Z" fill="#2A334E"/>
            <path d="M113.225 2.45508V10.0034C113.225 13.7506 110.448 16.2039 106.997 16.2039C103.574 16.2039 100.797 13.7506 100.797 10.0034V2.45508H102.954V10.0034C102.954 12.6723 104.76 14.3168 106.997 14.3168C109.235 14.3168 111.068 12.6723 111.068 10.0034V2.45508H113.225Z" fill="#2A334E"/>
            <path d="M114.561 15.3143L115.154 13.4002C116.448 13.9125 117.849 14.3168 119.17 14.3168C120.653 14.3168 122.055 13.6698 122.055 12.1871C122.055 11.1357 121.354 10.4887 119.143 9.92261C116.609 9.27561 114.776 8.11639 114.776 6.0406C114.776 3.47955 117.041 2.18555 119.548 2.18555C121.084 2.18555 122.54 2.61688 123.888 3.20997L123.295 4.98922C122.082 4.50397 120.788 4.07264 119.548 4.07264C118.173 4.07264 116.933 4.7466 116.933 6.0406C116.933 6.95718 117.661 7.60419 119.629 8.06248C122.459 8.70948 124.212 9.97652 124.212 12.1871C124.212 14.9369 121.785 16.2039 119.17 16.2039C117.58 16.2039 115.989 15.8535 114.561 15.3143Z" fill="#2A334E"/>
            <path d="M130.38 2.21869C133.823 1.7347 136.16 3.448 137.067 6.99569L137.293 8.59745L127.121 10.0269C127.713 13.0745 129.658 14.5162 132.869 14.1194C134.284 13.9205 135.608 13.4622 136.681 12.7669L137.378 14.4384C136.103 15.2438 134.653 15.7742 133.132 15.9881C128.967 16.5734 125.556 14.3849 124.952 10.0868C124.438 6.42945 126.57 2.8086 130.38 2.21869ZM130.669 4.08366C128.48 4.39131 127.229 6.14604 127.019 8.13569L134.868 7.03263C134.453 4.85857 132.698 3.79852 130.669 4.08366Z" fill="#2A334E"/>
            <path d="M9.77088 1.76196C6.897 1.30219 5.06311 1.25631 4.2692 1.62432L3.47327 1.65224C2.97334 1.65224 2.52116 1.80145 2.11672 2.09987C1.71227 2.39828 1.43281 2.78892 1.27832 3.27177C1.12384 3.75462 1.04659 4.21212 1.04659 4.64426C1.04659 5.0764 1.03467 5.66403 1.01081 6.40712C0.986961 7.15022 1.00257 8.08924 1.05763 9.22417C1.1127 10.3591 1.42422 11.6758 1.9922 13.1742C2.56018 14.6726 3.61729 15.6462 5.16354 16.0948C6.70979 16.5434 8.32879 16.6713 10.0206 16.4785C11.7123 16.2857 13.1159 15.6367 14.2314 14.5316C15.3468 13.4265 15.9086 11.9071 15.9168 9.97338C15.925 8.0397 15.6212 6.30266 15.0054 4.76224C14.3896 3.22182 12.6448 2.22173 9.77088 1.76196Z" fill="#FFC921" stroke="#FFC921"/>
            <path d="M19.0849 2.11422C16.2803 1.66533 14.4906 1.62054 13.7158 1.97984L12.9391 2.0071C12.4512 2.0071 12.0099 2.15277 11.6152 2.44413C11.2205 2.73548 10.9478 3.11687 10.797 3.5883C10.6462 4.05972 10.5709 4.50639 10.5709 4.9283C10.5709 5.35022 10.5592 5.92393 10.5359 6.64944C10.5127 7.37495 10.5279 8.29174 10.5816 9.39981C10.6354 10.5079 10.9394 11.7934 11.4937 13.2564C12.048 14.7193 13.0796 15.6698 14.5886 16.1078C16.0976 16.5458 17.6776 16.6707 19.3286 16.4825C20.9796 16.2942 22.3494 15.6606 23.4379 14.5816C24.5265 13.5026 25.0748 12.0192 25.0828 10.1313C25.0908 8.24338 24.7943 6.54745 24.1933 5.04349C23.5923 3.53953 21.8895 2.56311 19.0849 2.11422Z" fill="#FF4A8E" stroke="#FF4A8E"/>
            </g>
            <defs>
            <clipPath id="clip0_824_1675">
            <rect width="138" height="17" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>

        <WorkspaceSelection />

        <Menu menu={menu} setMenu={setMenu} />

        <div className='absolute bottom-[30px]'>
          <WorkspaceUsage />
        </div>

      </div>
  )
}

Sidebar.propTypes = {
  menu: PropTypes.string.isRequired,
  setMenu: PropTypes.func.isRequired
}

import React, { useContext, useState } from 'react'
import { ProjectContext } from '../../../context/ProjectContext'
import { createPortal } from 'react-dom'
import { ImportFiles } from '../../dashboard/dialog/importFiles'
import Tippy from '@tippyjs/react'
import cnt from '../../../constants'

export function AddFile () {
  const { project, setMeetings, workspace } = useContext(ProjectContext)
  const [showDialog, setShowDialog] = useState(false)

  return (
    <Tippy disabled={workspace.status === 'active' || workspace.additional_amount + workspace.sub_hours_left > cnt.min_time_needed} maxWidth={300} content='This feature is disabled since your account is out of free hours. Please upgrade to continue using our AI features.'>
     <div>
      <button
        disabled={workspace.status !== 'active' && workspace.additional_amount + workspace.sub_hours_left <= cnt.min_time_needed}
        onClick={() => setShowDialog(true)}
        className={`px-2.5 bg-white rounded-[30px] shadow border border-grey-200 flex justify-center items-center gap-1 ${workspace.status !== 'active' && workspace.additional_amount + workspace.sub_hours_left <= cnt.min_time_needed && 'opacity-50'}`}
      >
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2218_5412)">
          <path d="M4.08416 10.9992C3.35632 10.9992 2.6583 10.7227 2.14364 10.2304C1.62898 9.73809 1.33984 9.07041 1.33984 8.37422C1.33984 7.67803 1.62898 7.01035 2.14364 6.51806C2.6583 6.02578 3.35632 5.74922 4.08416 5.74922C4.25606 4.98341 4.75894 4.31042 5.48217 3.8783C5.84028 3.66434 6.2417 3.51596 6.66353 3.44162C7.08536 3.36728 7.51932 3.36845 7.94064 3.44505C8.36197 3.52166 8.7624 3.6722 9.11908 3.88808C9.47576 4.10397 9.7817 4.38096 10.0194 4.70326C10.2572 5.02556 10.422 5.38684 10.5046 5.76648C10.5872 6.14613 10.5859 6.53669 10.5008 6.91589H11.0842C11.6256 6.91589 12.145 7.13099 12.5278 7.51388C12.9107 7.89676 13.1258 8.41607 13.1258 8.95755C13.1258 9.49904 12.9107 10.0183 12.5278 10.4012C12.145 10.7841 11.6256 10.9992 11.0842 10.9992H10.5008" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M5.25 9.25L7 7.5L8.75 9.25" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M7 7.5V12.75" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
          <defs>
          <clipPath id="clip0_2218_5412">
          <rect width="14" height="14" fill="white" transform="translate(0 0.5)"/>
          </clipPath>
          </defs>
        </svg>
        <p className="text-zinc-700 text-xs font-extrabold font-['Manrope'] leading-[13.20px] tracking-wide">IMPORT</p>
      </button>
      {showDialog && createPortal(
        <ImportFiles projectId={project.id} projectName={project.name} handleClose={() => setShowDialog(false)} setMeetings={setMeetings} />,
        document.body
      )}
    </div>
    </Tippy>
  )
}

import React, { useContext } from 'react'
import { MeetingsContext } from '../../../context/MeetingContext'
import { formatDateHMMSS } from '../../../utils/formatDate'
import PropTypes from 'prop-types'

export function NeedTooltip ({ id, content, participantId, whoSaidIt, startTime }) {
  const { setScrollToNeed, participants } = useContext(MeetingsContext)

  return (
    <div className='p-2 w-[300px] text-left flex flex-col gap-[6px]'>
        <p className="font-bold text-[16px] font-['Manrope']">Need</p>
        <p className="font-normal text-[14px] font-['Manrope']">{content}</p>
        <div className='flex items-center'>
            <p className="font-semibold text-[14px] font-['Manrope']">{participantId ? participants[participantId]?.name : whoSaidIt} @ {formatDateHMMSS(startTime)}・</p>
            <button className="text-linkBlue font-semibold text-[14px] font-['Manrope']" onClick={() => setScrollToNeed(id)}>See Need ❯</button>
        </div>
    </div>
  )
}

NeedTooltip.propTypes = {
  id: PropTypes.string,
  content: PropTypes.string,
  participantId: PropTypes.string,
  whoSaidIt: PropTypes.string,
  startTime: PropTypes.number
}

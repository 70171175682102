import React, { useContext } from 'react'
import { ProjectContext } from '../../../../context/ProjectContext'
import { EachCategory } from './eachCategory'

export function GridByCategory () {
  const { categories } = useContext(ProjectContext)

  return (
    <div className="flex ml-10">
      {categories.map((category, index) => (category.content !== 'Uncategorized' || category.amount > 0) && (
        <div key={category.id} style={{ zIndex: categories.length - index }}>
          <EachCategory
            category={category}
          />
        </div>
      ))}
    </div>
  )
}

import React, { useContext } from 'react'
import { MeetingsContext } from '../../../context/MeetingContext'
import { formatDateHMMSS } from '../../../utils/formatDate'
import PropTypes from 'prop-types'

export function FeedbackTooltip ({ id, content, participantId, whoSaidIt, startTime, sentimentAnalysis }) {
  const { setScrollToFeedback, participants } = useContext(MeetingsContext)

  return (
    <div className='p-2 w-[300px] text-left flex flex-col gap-[6px]'>
        <p className="font-bold text-[16px] font-['Manrope']">Feedback: {sentimentAnalysis === 'positive' ? 'Positive' : sentimentAnalysis === 'negative' ? 'Negative' : 'Neutral'}</p>
        <p className="font-normal text-[14px] font-['Manrope']">{content}</p>
        <div className='flex items-center'>
            <p className="font-semibold text-[14px] font-['Manrope']">{participantId ? participants[participantId]?.name : whoSaidIt} @ {formatDateHMMSS(startTime)}・</p>
            <button className="font-semibold text-[14px] font-['Manrope'] text-linkBlue" onClick={() => setScrollToFeedback(id)}>See Feedback ❯</button>
        </div>
    </div>
  )
}

FeedbackTooltip.propTypes = {
  id: PropTypes.string,
  content: PropTypes.string,
  participantId: PropTypes.string,
  whoSaidIt: PropTypes.string,
  startTime: PropTypes.number,
  sentimentAnalysis: PropTypes.string
}

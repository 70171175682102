import React from 'react'
import { Feedback } from './feedback'
import PropTypes from 'prop-types'

export function Feedbacks ({ feedbacks }) {
  return (
    <div className='w-full pb-12'>
      <div className='pb-[10px] border-b-2 border-grey-200 mb-[36px]'>
        <p className="w-full text-[#40424A] text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">Feedback</p>
      </div>

      <div className='w-full flex flex-wrap ml-[44px] relative pb-[40px]'>
        {feedbacks.map(feedback => <Feedback key={feedback.id} feedback={feedback} />)}
      </div>
    </div>
  )
}

Feedbacks.propTypes = {
  feedbacks: PropTypes.array
}

import React from 'react'
import { Facilitators } from './facilitators'
import { Method } from './method'

export function OverviewSidebar () {
  return (
    <div>
      <p className="text-[#62718D] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Project preferences</p>

      <Facilitators />

      <Method />
    </div>
  )
}

export function getMeetingProvider (link) {
  const googleMeetURLRegex = /^(https:\/\/)?meet\.google\.com\/(lookup\/)?[a-zA-Z0-9-?=&]+$/
  const zoomURLRegex = /^(https?:\/\/)?(zoom\.us|us\d+web\.zoom\.us)\/j\/\d{9,11}(\/?|\?\S+)$/
  const teamsURLRegex = /^(https:\/\/)?(teams\.microsoft\.com\/l\/meetup-join\/|teams\.microsoft\.com\/_[a-zA-Z0-9/.-]+|[a-zA-Z0-9.-]+\.teams\.microsoft\.com\/[a-zA-Z0-9/.-]*|teams\.live\.com\/meet\/[0-9]+(\?p=[a-zA-Z0-9]+)?)$/

  if (googleMeetURLRegex.test(link)) return 'Google'
  if (zoomURLRegex.test(link)) return 'Zoom'
  if (teamsURLRegex.test(link)) return 'Teams'
  return ''
}

import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import userAvatar from '../../../assets/default_avatar.png'

export function UserDropdown () {
  const { googleSignOut, user } = useContext(AuthContext)
  const [openMenu, setOpenMenu] = useState(false)

  async function handleSignOut () {
    try {
      await googleSignOut()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className='relative' onMouseEnter={() => setOpenMenu(true)} onMouseLeave={() => setOpenMenu(false)}>
      <button className='flex mx-2 items-center'>
          {(user?.picture)
            ? <img className='h-8 w-8 mx-1 rounded-full border border-grey-200' src={user.picture} alt="user avatar" />
            : <img className='h-8 w-8 mx-1 rounded-full border border-grey-200' src={userAvatar} alt="user avatar" />
          }
          <svg className="w-4 h-4 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 10">
              <path d="M15.434 1.235A2 2 0 0 0 13.586 0H2.414A2 2 0 0 0 1 3.414L6.586 9a2 2 0 0 0 2.828 0L15 3.414a2 2 0 0 0 .434-2.179Z"/>
          </svg>
      </button>
      {openMenu && (
        <ul className="absolute p-2 w-24">
          <li className="bg-white p-1 rounded shadow flex justify-center">
            <button className="text-zinc font-semibold font-['Manrope']" onClick={handleSignOut}>Sign Out</button>
          </li>
        </ul>
      )}
    </div>
  )
}

import React from 'react'
import { Topbar } from './topbar'
import { Menu } from './menu'
import { Canvas } from './canvas'
import { Sidebar } from './sidebar'

export function Meeting () {
  return (
    <div className='h-screen bg-[#EBEBEB]'>
      <div className='w-full fixed top-0 bg-white z-10'>
        <Topbar />
      </div>
      <div className="fixed top-[60px] bg-white" style={{ height: 'calc(100% - 40px)' }}>
        <Menu />
      </div>
      {/* width calc: 100% - 75px (marginleft) - 30px (marginRight) - 485px (sidebar) - 56px (gap with sidebar) */}
      <div className='fixed top-[60px] left-[75px]' style={{ height: 'calc(100% - 40px)', width: 'calc(100% - 75px - 30px - 485px - 56px)' }}>
        <Canvas />
      </div>
      <div className='fixed top-[60px] right-[30px]' style={{ height: 'calc(100% - 40px)' }}>
        <Sidebar />
      </div>
    </div>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

export function Problems ({ problems }) {
  return (
      <div className='w-full'>
        <div className='mb-4 pb-[10px] flex justify-between items-center border-b-2 border-grey-200'>
          <p className="w-full text-[#40424A] text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">Problems</p>
          {/* <button className="w-[91.41px] h-[34.76px] px-[16.55px] bg-white rounded-[49.65px] shadow border-2 border-[#E4E4E4] flex items-center gap-[3.31px]">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_3498_24321)">
              <path d="M10.1367 4.83008V16.4967" stroke="#FF4A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M4.30859 10.6641H15.9753" stroke="#FF4A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_3498_24321">
              <rect width="20" height="20" fill="white" transform="translate(0.138672 0.664062)"/>
              </clipPath>
              </defs>
            </svg>
            <p className="text-[#40424A] text-lg font-medium font-['DM_Sans'] leading-tight">Add</p>
          </button> */}
        </div>

        <ul className="list-disc ml-6">
        {problems.map(problem => (
          <li key={problem.problem_number}>
            <span className="text-[#40424A] text-lg font-bold font-['Manrope'] leading-loose tracking-wide">{problem.title}:</span><span className="text-[#40424A] text-lg font-normal font-['Manrope'] leading-loose tracking-wide"> {problem.problem}</span>
          </li>
        ))}
        </ul>
      </div>
  )
}

Problems.propTypes = {
  problems: PropTypes.array
}

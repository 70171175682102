import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

export function Feedback ({ feedback }) {
  const feedbackRef = useRef(null)
  const textRef = useRef(null)
  const [smallFontSize, setSmallFontSize] = useState(false)
  const [truncateText, setTruncateText] = useState(0)
  const [truncateHover, setTruncateHover] = useState(false)

  useEffect(() => {
    if (textRef.current) {
      // Get the computed styles for the <p> element
      let styles = window.getComputedStyle(textRef.current)
      let lineHeight = parseFloat(styles.lineHeight)
      let height = textRef.current.offsetHeight
      let lines = Math.ceil(height / lineHeight)

      if (lines < 6) return
      setSmallFontSize(true)

      textRef.current.style.fontSize = '12px'
      textRef.current.style.lineHeight = '16px'

      styles = window.getComputedStyle(textRef.current)
      lineHeight = parseFloat(styles.lineHeight)
      height = textRef.current.offsetHeight
      lines = Math.ceil(height / lineHeight)

      if (lines > 6) {
        // here I need to truncate the text to get 6 lines no more
        // I want amount of chars from feedback.content to be equal to 6 lines
        styles = window.getComputedStyle(textRef.current)
        lineHeight = parseFloat(styles.lineHeight)
        let amountChars = feedback.content.length
        while (lines > 6 && amountChars > 0) {
          amountChars -= 3
          textRef.current.innerText = feedback.content.slice(0, amountChars)
          height = textRef.current.offsetHeight
          lines = Math.ceil(height / lineHeight)
        }
        textRef.current.innerText += feedback.content
        setTruncateText(amountChars - 3)
      }
    }
  }, [feedback.content])

  return (
    <div
      ref={feedbackRef}
      onMouseEnter={() => truncateText && setTruncateHover(true)}
      onMouseLeave={() => truncateText && setTruncateHover(false)}
      style={{ backgroundColor: `${feedback.sentiment_analysis === 'positive' ? '#BBE6B7' : feedback.sentiment_analysis === 'negative' ? '#E5A8AC' : '#FFF98E'}` }}
      className={`m-2 w-[210px] h-[134px] p-5 rounded shadow ${truncateText && 'transition-transform duration-300 hover:w-[230px] hover:h-[154px] hover:-m-[4px]'}`}
    >
      <p ref={textRef} className={`text-black font-semibold font-['Manrope'] ${smallFontSize ? 'text-xs' : 'text-sm'}`}>
        {truncateText && !truncateHover ? feedback.content.slice(0, truncateText) + '...' : feedback.content}
      </p>
    </div>
  )
}

Feedback.propTypes = {
  feedback: PropTypes.object
}

import React from 'react'
import PropTypes from 'prop-types'

export function SwitchCalendarDialog ({ handleClose, handleSwitch }) {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-opacity-30 bg-black z-10" onClick={handleClose}>
      {/* <div className="w-[570px] h-[334px] px-[34px] py-8 bg-white rounded-2xl border-2 border-red-700 justify-center items-center inline-flex"> */}
      <div onClick={(e) => e.stopPropagation()} className="absolute top-1/4 left-1/3 w-[650px] h-[250px] px-[34px] py-8 bg-white rounded-2xl border-2 border-warningRed">
        <header className='flex items-center justify-between'>
          <p className="w-[600px] text-zinc text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">Replace Calendar?</p>
          <button onClick={handleClose}>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 12L12 24" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12 12L24 24" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </header>
        <div className='mt-4 w-[485px]'>
          <span className="text-zinc-700 text-base font-normal font-['Manrope'] leading-[23px]">You can only have one calendar connected at a time. Disconnect the existing calendar and replace it with this one?</span>
        </div>
        <div className='mt-[26px] flex items-center gap-3'>
          <button onClick={() => {
            handleSwitch()
            handleClose()
          }} className="w-[215px] h-11 px-[19px] py-2.5 bg-warningRed rounded shadow-inner justify-center items-center gap-2 inline-flex">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2365_8899)">
              <path d="M4 7H20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M10 11V17" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M14 11V17" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_2365_8899">
              <rect width="24" height="24" fill="white"/>
              </clipPath>
              </defs>
            </svg>
            <p className="text-white text-base font-semibold font-['Manrope'] leading-tight">Replace Calendars</p>
          </button>
          <button onClick={handleClose} className="w-[86px] h-10 px-4 py-2.5 rounded shadow-inner border border-warningRed justify-center items-center gap-3 inline-flex">
            <p className="text-warningRed text-base font-semibold font-['Manrope'] leading-tight">Cancel</p>
          </button>
        </div>
      </div>
    </div>
  )
}

SwitchCalendarDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSwitch: PropTypes.func.isRequired
}

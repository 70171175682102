import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Radio, Space } from 'antd'
import useOutsideClick from '../../../../hooks/useOutsideClick'

export function ValidationFilter ({ validation, setValidation }) {
  const [openValidationFilter, setOpenValidationFilter] = useState(false)
  const validationFilterRef = useRef(null)

  useOutsideClick(validationFilterRef, () => {
    if (validationFilterRef) {
      setOpenValidationFilter(false)
    }
  })

  return (
      <div className='relative' ref={validationFilterRef}>
        <button className='flex items-center gap-[8px] px-[20px]' onClick={() => setOpenValidationFilter(!openValidationFilter)}>
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.49115 2.34375C5.50847 2.75052 4.61552 3.34683 3.86328 4.09866" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.10925 6.72656C1.70117 7.70778 1.49017 8.75971 1.48828 9.8224" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.11035 12.9199C2.51712 13.9026 3.11343 14.7955 3.86526 15.5478" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.49121 17.3008C7.47243 17.7089 8.52436 17.9199 9.58705 17.9217" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.6855 17.3018C13.6682 16.895 14.5612 16.2987 15.3134 15.5469" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.0664 12.9181C17.4745 11.9369 17.6855 10.885 17.6874 9.82227" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.0684 6.72552C16.6616 5.74284 16.0653 4.8499 15.3135 4.09766" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.6837 2.34362C11.7025 1.93555 10.6506 1.72455 9.58789 1.72266" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.88965 9.82334L8.68955 11.6233L12.2894 8.02344" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <p className="text-black text-base font-semibold font-['Manrope'] leading-tight">Any Validation</p>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3983_17643)">
            <circle cx="9.88218" cy="9.82065" r="7.27964" fill="#EBEBEB"/>
            <path d="M12.3082 9.01367L9.88162 11.4402L7.45508 9.01367" stroke="#40424A" strokeWidth="1.3866" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_3983_17643">
            <rect width="19.4124" height="19.4124" fill="white" transform="translate(0.175781 0.115234)"/>
            </clipPath>
            </defs>
          </svg>
        </button>
        {openValidationFilter && (
          <div className="absolute w-auto left-[-1px] top-8 p-2 bg-white shadow rounded-b border-x border-grey-200 z-10">
            <Radio.Group onChange={(e) => setValidation(e.target.value)} value={validation}>
              <Space direction="vertical">
              <Radio value={''}>Any Validation</Radio>
              <Radio value={'yes'}>Likely User</Radio>
              <Radio value={'maybe'}>Maybe User</Radio>
              <Radio value={'no'}>Unlikely User</Radio>
              </Space>
            </Radio.Group>
          </div>
        )}
      </div>
  )
}

ValidationFilter.propTypes = {
  validation: PropTypes.string,
  setValidation: PropTypes.func
}

/* eslint-disable react/no-unknown-property */
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import { customFetch } from '../../../utils'
import { Event } from './event'
// import NoEvents from '../../../assets/no_upcoming_events.png'

export function EventsWrap () {
  const [events, setEvents] = useState([])
  const [eventsLoading, setEventsLoading] = useState(true)
  const { user } = useContext(AuthContext)

  useEffect(() => {
    if (typeof user !== 'object' || Object.keys(user).length === 0) return
    const getCalendarEvents = async () => {
      const url = '/getCalendarEvents'
      const data = await customFetch(url, 'GET', null)
      if (data.error) return
      setEvents(data.events)
      setEventsLoading(false)
    }

    getCalendarEvents()
  }, [user])

  return (
    <>
    {!eventsLoading && events.length > 0 && (
      <div className='my-[37px]'>
          <p className="text-[#62708d] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">Upcoming Meetings</p>
            <div className='my-[14px] flex items-center flex-wrap gap-4'>
                {events.map(event => <Event key={event.id} event={event} />)}
            </div>
      </div>
    )}
    </>
    // <div className='my-[37px]'>
    //     <p className="text-[#62708d] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">Upcoming Meetings</p>
    //     {eventsLoading && (
    //       <div className='flex justify-center items-center'>
    //         <svg className='w-40 h-40' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stopColor="#FF156D"></stop><stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop><stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop><stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop><stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a1)" strokeWidth="15" strokeLinecap="round" strokeDasharray="200 1000" strokeDashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#FF156D" strokeWidth="15" strokeLinecap="round" cx="100" cy="100" r="70"></circle></svg>
    //       </div>
    //     )}
    //     {!eventsLoading && events.length === 0 && (
    //       <div className="mt-6 w-full bg-white rounded-2xl shadow pt-2 pb-8">
    //       <div className='flex justify-center items-center my-6'>
    //         <div className='mr-6'>
    //           <p className="text-grey-500 text-xl font-bold font-['Manrope'] leading-[23px]">No Upcoming Interviews</p>
    //           <p className="w-[420px] mt-6 text-grey-500 text-base font-normal font-['Manrope'] leading-[23px]">
    //             The<span className="text-grey-500 text-base font-bold font-['Manrope'] leading-[23px]"> Usermuse Bot </span>
    //             has not been invited to any user meetings yet. Simply invite him at:<span className="text-blue text-base font-bold font-['Manrope'] leading-[23px]"> invite@usermuse.co </span>
    //             (Or use a project-specific email to group sessions.)
    //           </p>
    //         </div>
    //         <img src={NoEvents} alt='No upcoming interviews' />
    //       </div>
    //       </div>
    //     )}
    //     {!eventsLoading && events.length > 0 && (
    //       <div className='my-[14px] flex items-center flex-wrap gap-4'>
    //           {events.map(event => <Event key={event.id} event={event} />)}
    //       </div>
    //     )}
    // </div>
  )
}

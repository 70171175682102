import React, { useContext } from 'react'
import { MeetingsContext } from '../../../../context/MeetingContext'

export function Problems () {
  const { meetingOverview } = useContext(MeetingsContext)

  return (
      <ul className="list-disc ml-6">
      {meetingOverview.problems?.map((problem, index) => (
        <li key={index}>
          <span className="text-[#40424A] text-lg font-bold font-['Manrope'] leading-loose tracking-wide capitalize">{problem.referent}:</span><span className="text-[#40424A] text-lg font-normal font-['Manrope'] leading-loose tracking-wide"> {problem.content}</span>
        </li>
      ))}
      </ul>

  )
}

import React, { useContext, useEffect, useRef, useState } from 'react'
import { MeetingInfo } from './stickyGrid/groupByMeeting/meetingInfo'
import { useCollapse } from 'react-collapsed'
import PropTypes from 'prop-types'
import { ProjectContext } from '../../context/ProjectContext'

export function Transcript ({ text }) {
  const { scrollToMeeting, scrollTopContainer, setSelectedMeeting } = useContext(ProjectContext)
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: true })
  const [prevScrollPos, setPrevScrollPos] = useState(0)

  const meetRef = useRef(null)

  useEffect(() => {
    if (!meetRef.current || !scrollTopContainer) return

    const checkIntersection = () => {
      if (!meetRef.current || !scrollTopContainer) return

      const meetRect = meetRef.current.getBoundingClientRect()
      const containerRect = scrollTopContainer.getBoundingClientRect()

      const currentScrollPos = scrollTopContainer.scrollTop
      let isIntersecting = false
      if (currentScrollPos > prevScrollPos) {
        // Scrolling down, check if intercepts the top of the container
        isIntersecting = meetRect.top >= containerRect.top && meetRect.top <= containerRect.top + 100
      } else {
        // Scrolling up, check if intercepts the bottom of the container
        isIntersecting = meetRect.bottom <= containerRect.bottom && meetRect.bottom >= containerRect.bottom - 500
      }

      if (isIntersecting) {
        setSelectedMeeting(text.meeting.id)
      }
      setPrevScrollPos(currentScrollPos)
    }

    // Check intersection on scroll
    scrollTopContainer.addEventListener('scroll', checkIntersection)

    return () => {
      scrollTopContainer.removeEventListener('scroll', checkIntersection)
    }
  }, [scrollTopContainer, prevScrollPos])

  useEffect(() => {
    // scroll to ref
    if (scrollToMeeting === text.meeting.id) {
      // Calculate the offset
      let topOffset = 0
      let element = meetRef.current
      while (element) {
        topOffset += element.offsetTop
        element = element.offsetParent
      }
      topOffset -= 180
      // Scroll to the element
      scrollTopContainer.scrollTo({
        top: topOffset,
        behavior: 'smooth'
      })
    }
  }, [scrollToMeeting])

  return (
      <div className='w-full mb-4' ref={meetRef}>
        <div>
          <MeetingInfo meeting={text.meeting} isExpanded={isExpanded} getToggleProps={getToggleProps} />
        </div>
        <div {...getCollapseProps()}>
          {text.content}
        </div>
        <div className="mt-6 border-b-2 border-grey-200"></div>
      </div>
  )
}

Transcript.propTypes = {
  text: PropTypes.object.isRequired
}

export function json2csv (items) {
  if (items.length === 0) return

  const header = Object.keys(items[0])
  const headerString = header.join(',')

  // handle null or undefined values here
  const replacer = (_, value) => value ?? ''

  const rowItems = items.map((row) =>
    header
      .map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(',')
  )

  // join header and body, and break into separate lines
  const csv = [headerString, ...rowItems].join('\r\n')

  return csv
}

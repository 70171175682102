import React, { useContext, useState } from 'react'
import { Sidebar } from './sidebar'
import { Checkbox } from 'antd'
import { customFetch } from '../../utils'
import toast from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'

export function CreateWorkspace () {
  const { user, setUser } = useContext(AuthContext)
  const [searchParams] = useSearchParams()
  const [workspaceName, setWorkspaceName] = useState('')
  const [allowMailExtension, setAllowMailExtension] = useState(true)
  const navigate = useNavigate()

  if (!user || !user.email) return

  const split = user.email.split('@')
  if (split.length < 2) throw new Error('Error with user extension')
  const mailExtension = split[1]
  const showMailExtension = mailExtension !== 'gmail.com' && mailExtension !== 'googlemail.com' && mailExtension !== 'outlook.com' && mailExtension !== 'hotmail.com' && !mailExtension.endsWith('.onmicrosoft.com')

  async function createWorkspace () {
    try {
      if (!workspaceName) return toast.error('Please enter a workspace name')
      const response = await customFetch('/createWorkspace', 'POST', { name: workspaceName, mailExtension: showMailExtension && allowMailExtension ? mailExtension : undefined })
      if (response.error) throw new Error(response.error)
      if (!Object.keys(response.newWorkspace).length) throw new Error('Error getting new workspace')
      setUser(prevUser => ({ ...prevUser, workspaces: { ...prevUser.workspaces, ...response.newWorkspace }, active_workspace: Object.keys(response.newWorkspace)[0] }))
      toast.success('Workspace created!')
      const planId = searchParams.get('plan_id')
      if (planId) navigate(`/dashboard/home?plan_id=${planId}`)
      else navigate('/dashboard/home')
    } catch (error) {
      console.error('Error creating workspace', error)
      toast.error('Error creating workspace')
    }
  }

  return (
        <div className='h-full flex'>
            <div className='w-3/5'>
              <div className='my-24 mx-36'>
                <div className='mb-20'>
                  <svg width="170" height="21" viewBox="0 0 170 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_664_3986)">
                    <path d="M52.4947 2.70508V12.0011C52.4947 16.6159 49.0751 19.6371 44.8255 19.6371C40.6091 19.6371 37.1895 16.6159 37.1895 12.0011V2.70508H39.8455V12.0011C39.8455 15.2879 42.0699 17.3131 44.8255 17.3131C47.5811 17.3131 49.8387 15.2879 49.8387 12.0011V2.70508H52.4947Z" fill="#2A334E"/>
                    <path d="M54.8047 18.5415L55.5351 16.1842C57.1287 16.815 58.8551 17.313 60.4819 17.313C62.3079 17.313 64.0343 16.5162 64.0343 14.6902C64.0343 13.3954 63.1711 12.5986 60.4487 11.9014C57.3279 11.1046 55.0703 9.67705 55.0703 7.12065C55.0703 3.96665 57.8591 2.37305 60.9467 2.37305C62.8391 2.37305 64.6319 2.90425 66.2919 3.63465L65.5615 5.82585C64.0675 5.22825 62.4739 4.69705 60.9467 4.69705C59.2535 4.69705 57.7263 5.52705 57.7263 7.12065C57.7263 8.24945 58.6227 9.04625 61.0463 9.61065C64.5323 10.4074 66.6903 11.9678 66.6903 14.6902C66.6903 18.0766 63.7023 19.637 60.4819 19.637C58.5231 19.637 56.5643 19.2055 54.8047 18.5415Z" fill="#2A334E"/>
                    <path d="M74.9471 2.41451C79.1882 1.81846 82.0663 3.92844 83.1832 8.29752L83.4604 10.2701L70.9343 12.0306C71.663 15.7837 74.0582 17.5592 78.0126 17.0705C79.7551 16.8256 81.3856 16.2612 82.7069 15.405L83.5662 17.4634C81.9957 18.4552 80.2101 19.1085 78.3361 19.3719C73.2073 20.0927 69.0065 17.3975 68.2626 12.1043C67.6296 7.60018 70.2549 3.141 74.9471 2.41451ZM75.3034 4.71127C72.6075 5.09015 71.0673 7.25114 70.8082 9.70145L80.474 8.34301C79.9636 5.66559 77.8021 4.36011 75.3034 4.71127Z" fill="#2A334E"/>
                    <path d="M85.9727 19.3382V2.70505H88.6287V4.56425L88.8279 4.66385C89.9567 3.33585 91.7495 2.37305 93.9075 2.37305H94.3723V4.69705H93.5755C91.5503 4.69705 89.8239 5.65985 88.6287 7.18705V19.3382H85.9727Z" fill="#2A334E"/>
                    <path d="M121.582 9.34505V19.305H118.926V9.34505C118.926 6.09145 117.266 4.69705 115.24 4.69705C113.215 4.69705 111.256 5.56025 110.227 7.78465C110.26 8.08345 110.294 8.38225 110.294 8.68105V19.305H107.638V8.68105C107.638 5.89225 105.978 4.69705 103.952 4.69705C101.794 4.69705 99.7692 5.56025 98.6736 7.81785V19.305H96.0176V2.70505H98.6736V4.79665L98.8728 4.86305C100.035 3.40225 102.06 2.37305 104.616 2.37305C106.708 2.37305 108.567 3.36905 109.53 5.26145C110.991 3.46865 113.116 2.37305 115.904 2.37305C119.058 2.37305 121.582 4.73025 121.582 9.34505Z" fill="#2A334E"/>
                    <path d="M139.208 2.70508V12.0011C139.208 16.6159 135.788 19.6371 131.538 19.6371C127.322 19.6371 123.902 16.6159 123.902 12.0011V2.70508H126.558V12.0011C126.558 15.2879 128.783 17.3131 131.538 17.3131C134.294 17.3131 136.552 15.2879 136.552 12.0011V2.70508H139.208Z" fill="#2A334E"/>
                    <path d="M140.854 18.5415L141.584 16.1842C143.178 16.815 144.904 17.313 146.531 17.313C148.357 17.313 150.083 16.5162 150.083 14.6902C150.083 13.3954 149.22 12.5986 146.498 11.9014C143.377 11.1046 141.119 9.67705 141.119 7.12065C141.119 3.96665 143.908 2.37305 146.996 2.37305C148.888 2.37305 150.681 2.90425 152.341 3.63465L151.61 5.82585C150.116 5.22825 148.523 4.69705 146.996 4.69705C145.302 4.69705 143.775 5.52705 143.775 7.12065C143.775 8.24945 144.672 9.04625 147.095 9.61065C150.581 10.4074 152.739 11.9678 152.739 14.6902C152.739 18.0766 149.751 19.637 146.531 19.637C144.572 19.637 142.613 19.2055 140.854 18.5415Z" fill="#2A334E"/>
                    <path d="M160.334 2.41451C164.575 1.81846 167.453 3.92844 168.57 8.29752L168.847 10.2701L156.321 12.0306C157.05 15.7837 159.445 17.5592 163.399 17.0705C165.142 16.8256 166.772 16.2612 168.094 15.405L168.953 17.4634C167.382 18.4552 165.597 19.1085 163.723 19.3719C158.594 20.0927 154.393 17.3975 153.649 12.1043C153.016 7.60018 155.642 3.141 160.334 2.41451ZM160.69 4.71127C157.994 5.09015 156.454 7.25114 156.195 9.70145L165.861 8.34301C165.35 5.66559 163.189 4.36011 160.69 4.71127Z" fill="#2A334E"/>
                    <path d="M11.8016 1.85064C8.26232 1.28442 6.00383 1.22792 5.02611 1.68114L4.0459 1.71552C3.43023 1.71552 2.87335 1.89928 2.37527 2.26679C1.87718 2.6343 1.53301 3.11537 1.34276 3.71002C1.15251 4.30466 1.05738 4.86808 1.05738 5.40028C1.05738 5.93248 1.04269 6.65615 1.01332 7.57129C0.983942 8.48644 1.00316 9.64286 1.07098 11.0406C1.13879 12.4383 1.52244 14.0598 2.22192 15.9051C2.92141 17.7505 4.22327 18.9494 6.12751 19.5019C8.03176 20.0545 10.0256 20.212 12.1091 19.9745C14.1925 19.737 15.9211 18.9378 17.2948 17.5768C18.6685 16.2158 19.3604 14.3446 19.3705 11.9632C19.3806 9.58186 19.0064 7.44264 18.248 5.54557C17.4896 3.64851 15.3408 2.41687 11.8016 1.85064Z" fill="#FFC921" stroke="#FFC921"/>
                    <path d="M23.2718 2.28284C19.8178 1.73002 17.6137 1.67486 16.6596 2.11735L15.703 2.15091C15.1021 2.15091 14.5587 2.33032 14.0726 2.68913C13.5865 3.04794 13.2506 3.51763 13.065 4.0982C12.8793 4.67877 12.7865 5.22886 12.7865 5.74846C12.7865 6.26806 12.7721 6.9746 12.7435 7.86809C12.7148 8.76158 12.7336 9.89063 12.7997 11.2552C12.8659 12.6199 13.2403 14.203 13.9229 16.0047C14.6056 17.8064 15.8761 18.9769 17.7344 19.5164C19.5928 20.0558 21.5386 20.2096 23.5718 19.9777C25.6051 19.7459 27.292 18.9655 28.6326 17.6368C29.9732 16.308 30.6484 14.4811 30.6583 12.1561C30.6681 9.83107 30.303 7.74248 29.5629 5.89032C28.8228 4.03815 26.7257 2.83566 23.2718 2.28284Z" fill="#FF4A8E" stroke="#FF4A8E"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_664_3986">
                    <rect width="170.002" height="21" fill="white"/>
                    </clipPath>
                    </defs>
                  </svg>
                </div>

                <p className="text-3xl font-bold font-['DM_Sans'] text-zinc">Create a Workspace</p>
                <p className="text-l font-medium font-['DM_Sans'] text-zinc my-2">A workspace is a space for your organization to <br/>  collaborate on user research projects.</p>
                <div className='mt-[40px] flex items-center'>
                  <p className="text-[#40424A] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Workspace name</p>
                  <p className="ml-[5px] mt-[5px] text-[#FF4A8E] text-[24px] font-medium font-['DM_Sans']">*</p>
                </div>
                <input
                  className="w-[601px] px-5 py-2.5 bg-grey-100 rounded shadow-inner border border-grey-200 placeholder-grey-400"
                  placeholder='Enter Workspace Name'
                  value={workspaceName}
                  onChange={(e) => setWorkspaceName(e.target.value)}
                />
                {showMailExtension && (
                  <div className='mt-4 flex items-center'>
                    <Checkbox checked={allowMailExtension} onChange={(e) => setAllowMailExtension(e.target.checked)} />
                    {/* <Checkbox checked={allowMailExtension} onClick={() => setAllowMailExtension(!allowMailExtension)} /> */}
                    <div className='ml-2'><span className="text-black text-lg font-normal font-['DM_Sans'] leading-[23px]">Accept anyone with an </span><span className="text-black text-lg font-bold font-['DM_Sans'] leading-[23px]">@{mailExtension} </span><span className="text-black text-lg font-normal font-['DM_Sans'] leading-[23px]">address</span></div>
                  </div>
                )}
                <button className="ml-[382px] mt-[71px] w-[219px] h-12 px-[30px] py-3.5 bg-[#FF4A8E] rounded justify-center items-center gap-2.5 inline-flex" onClick={createWorkspace}>
                  <p className="text-center text-white text-lg font-medium font-['DM_Sans'] leading-tight">Create & Continue</p>
                </button>

              </div>
            </div>
            <div className='w-2/5 bg-grey-100'>
              <Sidebar />
            </div>
        </div>
  )
}

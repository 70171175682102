import React, { useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { ProjectContext } from '../../../../context/ProjectContext'
import { customFetch } from '../../../../utils'
import toast from 'react-hot-toast'
import { createPortal } from 'react-dom'
import { ImportFiles } from '../../../dashboard/dialog/importFiles'
import cnt from '../../../../constants'
import Tippy from '@tippyjs/react'

export function EmptyMeeting ({ meeting }) {
  const { setListenRealTime, setMeetings, setSelectedMeeting, project, notesInputRef, workspace } = useContext(ProjectContext)
  const [link, setLink] = useState('')
  const [showImportDialog, setShowImportDialog] = useState(false)
  const shouldDisable = useMemo(() => {
    return workspace.status !== 'active' && workspace.additional_amount + workspace.sub_hours_left <= cnt.min_time_needed
  }, [workspace.status, workspace.additional_amount, workspace.sub_hours_left])

  async function sendBot () {
    try {
      // have to reload real time listening with the new meeting
      setListenRealTime(false)
      const newMeet = await customFetch('/createBot', 'POST', { link, projectId: project.id, projectName: project.name, projectDescription: project.description, meetingId: meeting.id })
      setMeetings(prevMeetings => prevMeetings.map(meet => meet.id === meeting.id ? newMeet.meeting : meet))
      setListenRealTime(true)
      toast.success('Great! Go accept the bot into the meeting.', { duration: 3000 })
    } catch (error) {
      console.error('Error sending bot:', error)
    }
  }

  return (
    <>
      <p className="mt-[18px] text-slate text-base font-normal font-['Manrope'] leading-[23px]">You can add notes manually to this meeting, upload a meeting to be analyzed, or paste a live meeting link for live transcription.</p>
      <div className="mt-[30px] flex items-center">
        <div className="pt-[30px] flex flex-col items-center w-[225px] h-[134px] bg-white rounded-lg shadow border border-neutral-200">
          <p className="text-center text-strongSlate text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Add Live Meeting</p>
          <Tippy disabled={!shouldDisable} maxWidth={300} content='This feature is disabled since your account is out of free hours. Please upgrade to continue using our AI features.'>
          <div className="mt-4 ml-1 w-[184px] h-[43px] relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-4 pointer-events-none">
              <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.666 5.50061L18.472 3.22461C18.6328 3.14844 18.8116 3.11249 18.9913 3.12015C19.171 3.12782 19.3456 3.17885 19.4986 3.26841C19.6516 3.35797 19.7779 3.48308 19.8656 3.63188C19.9532 3.78067 19.9993 3.94822 19.9993 4.11861V10.8826C19.9993 11.053 19.9532 11.2205 19.8656 11.3693C19.7779 11.5181 19.6516 11.6432 19.4986 11.7328C19.3456 11.8224 19.171 11.8734 18.9913 11.8811C18.8116 11.8887 18.6328 11.8528 18.472 11.7766L13.666 9.50061V5.50061Z" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11.5556 1.5H3.11111C1.94518 1.5 1 2.39543 1 3.5V11.5C1 12.6046 1.94518 13.5 3.11111 13.5H11.5556C12.7215 13.5 13.6667 12.6046 13.6667 11.5V3.5C13.6667 2.39543 12.7215 1.5 11.5556 1.5Z" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <input
                disabled={shouldDisable}
                type="url"
                id="default-search"
                className="h-full w-full ps-[50px] text-sm placeholder-grey-400 text-grey-400 bg-grey-100 rounded border border-grey-200"
                placeholder="Paste link"
                value={link}
                onChange={e => setLink(e.target.value)}
                onKeyDown={e => e.key === 'Enter' && sendBot()}
                required
            />
          </div>
          </Tippy>
        </div>

        <div className="mx-5 pt-[30px] flex flex-col items-center w-[225px] h-[134px] bg-white rounded-lg shadow border border-neutral-200">
          <p className="text-center text-strongSlate text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Upload Recording</p>
          <Tippy disabled={!shouldDisable} maxWidth={300} content='This feature is disabled since your account is out of free hours. Please upgrade to continue using our AI features.'>
          <div>
          <button className={`mt-1 ${shouldDisable && 'opacity-50'}`} onClick={() => setShowImportDialog(true)} disabled={shouldDisable}>
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2344_8604)">
              <path d="M18.6665 47.9992C15.3393 47.9992 12.1483 46.7349 9.79557 44.4845C7.44284 42.2341 6.12109 39.1818 6.12109 35.9992C6.12109 32.8166 7.44284 29.7644 9.79557 27.5139C12.1483 25.2635 15.3393 23.9992 18.6665 23.9992C19.4524 20.4984 21.7513 17.4218 25.0574 15.4465C26.6945 14.4683 28.5296 13.79 30.4579 13.4502C32.3863 13.1104 34.3701 13.1157 36.2962 13.4659C38.2222 13.8161 40.0528 14.5043 41.6833 15.4912C43.3139 16.4781 44.7125 17.7443 45.7992 19.2177C46.886 20.691 47.6397 22.3426 48.0173 24.0781C48.3949 25.8137 48.389 27.5991 47.9999 29.3326H50.6665C53.1419 29.3326 55.5159 30.3159 57.2662 32.0662C59.0166 33.8166 59.9999 36.1905 59.9999 38.6659C59.9999 41.1412 59.0166 43.5152 57.2662 45.2655C55.5159 47.0159 53.1419 47.9992 50.6665 47.9992H47.9999" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M24 40L32 32L40 40" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M32 32V56" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <g filter="url(#filter0_d_2344_8604)">
              <g filter="url(#filter1_d_2344_8604)">
              <rect x="39.166" y="39.7168" width="14.8515" height="16.5432" rx="7.42573" fill="#FF001F"/>
              </g>
              <ellipse cx="46.5918" cy="47.9893" rx="3.71286" ry="4.1358" fill="white"/>
              </g>
              <defs>
              <filter id="filter0_d_2344_8604" x="36.166" y="38.7168" width="18.8516" height="20.543" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="-1" dy="1"/>
              <feGaussianBlur stdDeviation="1"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2344_8604"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2344_8604" result="shape"/>
              </filter>
              <filter id="filter1_d_2344_8604" x="38.3299" y="39.4381" width="15.9664" height="17.6578" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="-0.278716" dy="0.278716"/>
              <feGaussianBlur stdDeviation="0.278716"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2344_8604"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2344_8604" result="shape"/>
              </filter>
              <clipPath id="clip0_2344_8604">
              <rect width="64" height="64" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </button>
          </div>
          </Tippy>
        </div>
        {showImportDialog && createPortal(
          <ImportFiles
            projectId={project.id}
            projectName={project.name}
            handleClose={() => setShowImportDialog(false)}
            setMeetings={setMeetings}
            limitFiles={1}
            meetingId={meeting.id}
          />,
          document.body
        )}

        <div className="pt-[30px] flex flex-col items-center w-[225px] h-[134px] bg-white rounded-lg shadow border border-neutral-200">
          <p className="text-center text-strongSlate text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">ADD NOTES</p>
          <button
            className="mt-3"
            onClick={() => {
              notesInputRef.focus()
              setSelectedMeeting(meeting.id)
            }}
          >
            <svg width="66" height="51" viewBox="0 0 66 51" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2344_8646)">
              <path d="M42.2363 42.5L57.1113 27.625" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M42.2363 42.5V29.75C42.2363 29.1864 42.4602 28.6459 42.8587 28.2474C43.2572 27.8489 43.7977 27.625 44.3613 27.625H57.1113V12.75C57.1113 11.6228 56.6636 10.5418 55.8665 9.7448C55.0695 8.94777 53.9885 8.5 52.8613 8.5H27.3613C26.2342 8.5 25.1532 8.94777 24.3561 9.7448C23.5591 10.5418 23.1113 11.6228 23.1113 12.75V38.25C23.1113 39.3772 23.5591 40.4582 24.3561 41.2552C25.1532 42.0522 26.2342 42.5 27.3613 42.5H42.2363Z" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <g clipPath="url(#clip1_2344_8646)">
              <path d="M9.5625 19.9219V31.0781" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M3.98438 25.5H15.1406" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_2344_8646">
              <rect width="51" height="51" fill="white" transform="translate(14.6113)"/>
              </clipPath>
              <clipPath id="clip1_2344_8646">
              <rect width="19.125" height="19.125" fill="white" transform="translate(0 15.9375)"/>
              </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    </>
  )
}

EmptyMeeting.propTypes = {
  meeting: PropTypes.object
}

/* eslint-disable react/no-unknown-property */
import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import './effects.css'
import { ProjectContext } from '../../../context/ProjectContext'
import { customFetch } from '../../../utils'
import toast from 'react-hot-toast'
import { SelectBar } from './selectBar'
import { createPortal } from 'react-dom'
import { EditNoteDialog } from './editNoteDialog'
import cnt from '../../../constants'
import { useDebounce } from '../../../hooks/useDebounce'

export const Note = ({ note, meetingId, selected, handleNoteSelect, noteHover, setNoteHover }) => {
  const { meetings, newNoteId, setNotes, categories, setCategories, setScrollToEnd } = useContext(ProjectContext)
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [noteContent, setNoteContent] = useState('')
  const debouncedNoteContent = useDebounce(noteContent, 1000)
  const divRef = useRef(null)
  const textRef = useRef(null)
  const [smallFontSize, setSmallFontSize] = useState(false)
  const [truncateText, setTruncateText] = useState(0)
  const [truncateHover, setTruncateHover] = useState(false)

  useEffect(() => {
    if (textRef.current) {
      // Get the computed styles for the <p> element
      let styles = window.getComputedStyle(textRef.current)
      let lineHeight = parseFloat(styles.lineHeight)
      let height = textRef.current.offsetHeight
      let lines = Math.ceil(height / lineHeight)

      if (lines < 6) return
      setSmallFontSize(true)

      textRef.current.style.fontSize = '12px'
      textRef.current.style.lineHeight = '16px'

      styles = window.getComputedStyle(textRef.current)
      lineHeight = parseFloat(styles.lineHeight)
      height = textRef.current.offsetHeight
      lines = Math.ceil(height / lineHeight)

      if (lines > 6) {
        // here I need to truncate the text to get 6 lines no more
        // I want amount of chars from note.content to be equal to 6 lines
        styles = window.getComputedStyle(textRef.current)
        lineHeight = parseFloat(styles.lineHeight)
        let amountChars = note.content.length
        while (lines > 6 && amountChars > 0) {
          amountChars -= 3
          textRef.current.innerText = note.content.slice(0, amountChars)
          height = textRef.current.offsetHeight
          lines = Math.ceil(height / lineHeight)
        }
        textRef.current.innerText += note.content
        setTruncateText(amountChars - 3)
      }
    }
  }, [note.content])

  useEffect(() => {
    if (newNoteId === note.id) {
      setScrollToEnd(meetingId)
    }
  }, [newNoteId])

  useEffect(() => {
    if (!noteContent) return
    const handleChangeNoteContent = async (content) => {
      try {
        setNotes(prevNotes => prevNotes.map(n => n.id === note.id ? { ...n, content } : n))
        await customFetch('/updateNoteContent', 'PUT', { noteId: note.id, content })
      } catch (error) {
        console.log(error)
        toast.error('Whoops! Something went wrong. Please try again.')
      }
    }
    handleChangeNoteContent(noteContent)
  }, [debouncedNoteContent])

  const handleChangeNoteCategory = async (categoryId) => {
    try {
      // if (categoryId !== 'Uncategorized')
      if (!note.category_id) note.category_id = 'Uncategorized'
      const newCategories = JSON.parse(JSON.stringify(categories))
      const prevCatIndex = newCategories.findIndex(c => c.id === note.category_id)
      const newCatIndex = newCategories.findIndex(c => c.id === categoryId)
      newCategories[prevCatIndex].amount--
      newCategories[newCatIndex].amount++
      setCategories(newCategories)
      setNotes(prevNotes => prevNotes.map(n => n.id === note.id ? { ...n, category_id: categoryId === 'Uncategorized' ? undefined : categoryId } : n))
      await customFetch('/updateNoteCategory', 'PUT', { noteId: note.id, categoryId })
    } catch (error) {
      console.log(error)
      toast.error('Whoops! Something went wrong. Please try again.')
    }
  }

  const handleChangeNoteColor = async (color) => {
    try {
      setNotes(prevNotes => prevNotes.map(n => n.id === note.id ? { ...n, color } : n))
      await customFetch('/updateNoteColor', 'PUT', { noteId: note.id, color })
    } catch (error) {
      console.log(error)
      toast.error('Whoops! Something went wrong. Please try again.')
    }
  }

  const handleDeleteNote = async () => {
    try {
      setNotes(prevNotes => prevNotes.filter(n => n.id !== note.id))
      setShowEditDialog(false)
      toast.success('Note deleted successfully')
      await customFetch('/deleteNote', 'DELETE', { noteId: note.id })
    } catch (error) {
      console.log(error)
      toast.error('Whoops! Something went wrong. Please try again.')
    }
  }

  return (
      <div onDoubleClick={() => setShowEditDialog(true)} key={note.id} onMouseEnter={() => selected && setNoteHover(note.id)} onClick={(event) => handleNoteSelect(note.id, event)} className={'m-2 relative'}>
        {selected && noteHover === note.id && <SelectBar />}
        {showEditDialog && createPortal(
          <EditNoteDialog
            note={note}
            meeting={meetings.find(meet => meet.id === meetingId) || {}}
            close={() => setShowEditDialog(false)}
            changeNoteContent={(content) => setNoteContent(content)}
            changeNoteCategory={handleChangeNoteCategory}
            changeNoteColor={handleChangeNoteColor}
            deleteNote={handleDeleteNote}
          />,
          document.body
        )}
        <div
          ref={divRef}
          onMouseEnter={() => truncateText && setTruncateHover(true)}
          onMouseLeave={() => truncateText && setTruncateHover(false)}
          style={{ backgroundColor: note.color || cnt.default_notes_color }}
          className={`w-[210px] h-[134px] p-5 rounded shadow ${newNoteId === note.id && ' colorFade'} ${truncateText && 'transition-transform duration-300 hover:w-[230px] hover:h-[154px] hover:-m-[10px]'}  ${selected && 'border-2 border-pink rounded-[6px]'}`}
        >
          <p ref={textRef} className={`text-black font-semibold font-['Manrope'] ${smallFontSize ? 'text-xs' : 'text-sm'}`}>
            {truncateText && !truncateHover ? note.content.slice(0, truncateText) + '...' : note.content}
          </p>
        </div>
      </div>
  )
}

Note.propTypes = {
  note: PropTypes.object,
  meetingId: PropTypes.string,
  selected: PropTypes.bool,
  handleNoteSelect: PropTypes.func,
  noteHover: PropTypes.string,
  setNoteHover: PropTypes.func
}

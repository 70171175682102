/* eslint-disable react/no-unknown-property */
import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cnt from '../../../../constants'
import { MeetingsContext } from '../../../../context/MeetingContext'

export const Need = ({ need }) => {
  const needRef = useRef(null)
  const divRef = useRef(null)
  const textRef = useRef(null)
  const [smallFontSize, setSmallFontSize] = useState(false)
  const [truncateText, setTruncateText] = useState(0)
  const [truncateHover, setTruncateHover] = useState(false)
  const { scrollToNeed, scrollTopContainer } = useContext(MeetingsContext)

  useEffect(() => {
    if (textRef.current) {
      // Get the computed styles for the <p> element
      let styles = window.getComputedStyle(textRef.current)
      let lineHeight = parseFloat(styles.lineHeight)
      let height = textRef.current.offsetHeight
      let lines = Math.ceil(height / lineHeight)

      if (lines < 6) return
      setSmallFontSize(true)

      textRef.current.style.fontSize = '12px'
      textRef.current.style.lineHeight = '16px'

      styles = window.getComputedStyle(textRef.current)
      lineHeight = parseFloat(styles.lineHeight)
      height = textRef.current.offsetHeight
      lines = Math.ceil(height / lineHeight)

      if (lines > 6) {
        // here I need to truncate the text to get 6 lines no more
        // I want amount of chars from note.content to be equal to 6 lines
        styles = window.getComputedStyle(textRef.current)
        lineHeight = parseFloat(styles.lineHeight)
        let amountChars = need.content.length
        while (lines > 6 && amountChars > 0) {
          amountChars -= 3
          textRef.current.innerText = need.content.slice(0, amountChars)
          height = textRef.current.offsetHeight
          lines = Math.ceil(height / lineHeight)
        }
        textRef.current.innerText += need.content
        setTruncateText(amountChars - 3)
      }
    }
  }, [need.content])

  useEffect(() => {
    if (!needRef.current || !scrollTopContainer || !scrollToNeed) return
    if (scrollToNeed === need.id) {
      const topPosition = needRef.current.getBoundingClientRect().top + scrollTopContainer.scrollTop
      scrollTopContainer.scrollTo({
        top: topPosition - 100,
        behavior: 'smooth'
      })
    }
  }, [scrollToNeed])

  return (
      <div className='m-2 relative' ref={needRef}>
        <div
          ref={divRef}
          onMouseEnter={() => truncateText && setTruncateHover(true)}
          onMouseLeave={() => truncateText && setTruncateHover(false)}
          style={{ backgroundColor: need.color || cnt.default_notes_color }}
          className={`w-[210px] h-[134px] p-5 rounded shadow ${truncateText && 'transition-transform duration-300 hover:w-[230px] hover:h-[154px] hover:-m-[10px]'}`}
        >
          <p ref={textRef} className={`text-black font-semibold font-['Manrope'] ${smallFontSize ? 'text-xs' : 'text-sm'}`}>
            {truncateText && !truncateHover ? need.content.slice(0, truncateText) + '...' : need.content}
          </p>
        </div>
      </div>
  )
}

Need.propTypes = {
  need: PropTypes.object
}

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { exportcsv, json2csv } from '../../../utils'
import csvLogo from './csvLogo'
import copySheetLogo from './copySheetLogo'
import { ProjectContext } from '../../../context/ProjectContext'

export function ExportDialog ({ notes, meetings, handleClose }) {
  const { categories } = useContext(ProjectContext)

  function exportCsv () {
    const newNotes = notes.map((note, index) => {
      const meet = meetings.filter(meeting => meeting.id === note.meeting_id)
      if (meet.length === 0) return {}

      const newNote = { ...note }
      delete newNote.bot_id
      delete newNote.id
      newNote.note_id = index + 1
      newNote.meeting_url = meet[0].meeting_url
      newNote.thread_id = meet[0].thread_id
      newNote.created_at = new Date(note.created_at).toLocaleString()
      const catIndex = categories.findIndex(cat => cat.id === newNote.category_id)
      if (catIndex === -1) {
        newNote.category = 'Uncategorized'
      } else {
        newNote.category = categories[catIndex].content
      }
      newNote.tags = newNote.tags.map(tag => `'${tag}'`).join(' ')

      // Order by key
      const orderedNote = {}
      Object.keys(newNote).sort().forEach(key => { orderedNote[key] = newNote[key] })

      return orderedNote
    })

    const csv = json2csv(newNotes)
    exportcsv(csv, 'notes')
  }

  async function copyTextToClipboard () {
    try {
      const text = notes.map(note => note.content).join('\t')
      await navigator.clipboard.writeText(text)
      alert('Copied!')
    } catch (err) {
      alert('Failed to copy!')
    }
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-opacity-30 bg-black" onClick={() => handleClose(true)}>
      <div className="absolute top-1/4 left-1/4 right-1/4 bg-white px-5 py-3 rounded-md" onClick={(e) => e.stopPropagation()}>
        <header className='flex justify-end'>
          <button onClick={() => handleClose(true)}>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 12L12 24" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12 12L24 24" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </header>
        <div className='ml-12 mb-10'>
          <p className="text-zinc text-[26px] font-semibold font-['Manrope'] mb-4">Export Stories</p>
          <p className="text-zinc text-base font-normal font-['Manrope'] mb-6">Please select a platform you’d like to export your stories to.</p>
          <button className='mx-4 w-[118px] h-[135px] relative bg-gradient-to-tr from-stone-900 to-stone-600 rounded-lg' onClick={copyTextToClipboard}>
            <div className='w-[49px] h-[49px] left-[35px] top-[27px] absolute shadow'>
              {copySheetLogo}
            </div>
            <p className="w-[118px] h-[22px] left-0 top-[96px] absolute text-center text-white text-[13px] font-semibold font-['Manrope']">Copy to Sheet</p>
          </button>
          <button className='mx-4 w-[118px] h-[135px] relative bg-gradient-to-tr from-stone-900 to-stone-600 rounded-lg' onClick={exportCsv}>
            <div className='w-[49px] h-[49px] left-[35px] top-[27px] absolute shadow'>
              {csvLogo}
            </div>
            <p className="w-[118px] h-[22px] left-0 top-[96px] absolute text-center text-white text-[13px] font-semibold font-['Manrope']">CSV Export</p>
          </button>
        </div>
      </div>
    </div>
  )
}

ExportDialog.propTypes = {
  notes: PropTypes.array.isRequired,
  meetings: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired
}

import React, { useContext, useEffect, useRef, useState } from 'react'
import { UserDropdown } from '../../dashboard/topbar/userDropdown'
import { useNavigate } from 'react-router-dom'
import { ProjectContext } from '../../../context/ProjectContext'
import { createPortal } from 'react-dom'
import { ExportDialog } from './exportDialog'
import { EmailInvite } from './emailInvite'
import { useDebounce } from '../../../hooks/useDebounce'
import { customFetch } from '../../../utils'
import toast from 'react-hot-toast'

export function Topbar () {
  const [showExportDialog, setShowExportDialog] = useState(false)
  const { project, setProject, meetings, notes } = useContext(ProjectContext)
  const navigate = useNavigate()

  const nameRef = useRef(null)
  const [nameCharsLimit, setNameCharsLimit] = useState(0)
  const debouncedProjectName = useDebounce(project.name, 500)
  const debouncedProjectDesc = useDebounce(project.description, 500)

  useEffect(() => {
    if (!project.name || !project.description) return
    async function updateProject () {
      await customFetch('/updateProjectNameDescription', 'PUT', { id: project.id, name: project.name, description: project.description })
    }
    updateProject()
  }, [debouncedProjectName])

  useEffect(() => {
    if (!project.name || !project.description) return
    async function updateProject () {
      await customFetch('/updateProjectNameDescription', 'PUT', { id: project.id, name: project.name, description: project.description })
    }
    updateProject()
  }, [debouncedProjectDesc])

  function handleChangeName (e) {
    if (e.target.value.length > project.name.length && e.target.value.length > nameCharsLimit * 2) return toast.error('Name is too long!')
    setProject(prevProject => ({ ...prevProject, name: e.target.value }))
  }

  useEffect(() => {
    if (nameRef.current) {
      const charWidth = 33
      const charsPerLine = Math.floor(nameRef.current.offsetWidth / charWidth)
      setNameCharsLimit(charsPerLine)
    }
  }, [])

  return (
        <div className="border-b-2 border-grey-200">
            <div className="flex flex-wrap items-center mx-10 my-6">
                <div className="flex grow items-center relative">
                    <button className="w-8 h-[22px] bg-pink rounded-sm relative" onClick={() => navigate('/dashboard/home')}>
                        <div className="absolute inset-y-0 flex items-center start-0 ps-2 pointer-events-none">
                            <svg width="11" height="10" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 6.75C12.4142 6.75 12.75 6.41421 12.75 6C12.75 5.58579 12.4142 5.25 12 5.25V6.75ZM0.46967 5.46967C0.176777 5.76256 0.176777 6.23744 0.46967 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.46967 5.46967ZM12 5.25H1V6.75H12V5.25Z" fill="white"/>
                            </svg>
                        </div>
                    </button>
                    {/* <input
                        type='text'
                        // className="w-full h-full ps-10 text-sm placeholder-grey-400 text-grey-400 bg-grey-100 rounded border border-grey-200"
                        className="ml-4 text-black text-[26px] font-semibold font-['Manrope'] leading-[29.90px]"
                        placeholder="Search interview..."
                        value={project.name}
                        onChange={e => console.log(e.target.value)}
                    /> */}
                    <input
                        ref={nameRef}
                        type='text'
                        className="ml-4 text-black bg-white text-[26px] font-semibold font-['Manrope'] leading-[29.90px] grow"
                        value={project.name}
                        onChange={handleChangeName}
                        onKeyDown={e => e.key === 'Enter' && nameRef.current.blur()}
                    />
                    {/* <p className="ml-4 text-black text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">{project.name}</p> */}
                </div>
                <div className="flex justify-end h-full">
                    <EmailInvite />
                    {/* <button className="flex mx-2 font-bold px-4 rounded border items-center bg-pink text-white shadow">
                        <svg width="12" height="16" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.78906 3.84211C4.78906 3.08833 5.0885 2.36543 5.6215 1.83243C6.15449 1.29944 6.87739 1 7.63117 1C8.38494 1 9.10784 1.29944 9.64084 1.83243C10.1738 2.36543 10.4733 3.08833 10.4733 3.84211V8.57895C10.4733 9.33272 10.1738 10.0556 9.64084 10.5886C9.10784 11.1216 8.38494 11.4211 7.63117 11.4211C6.87739 11.4211 6.15449 11.1216 5.6215 10.5886C5.0885 10.0556 4.78906 9.33272 4.78906 8.57895V3.84211Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M1 8.57812C1 10.3369 1.69868 12.0237 2.94234 13.2674C4.18601 14.511 5.87278 15.2097 7.63158 15.2097C9.39038 15.2097 11.0772 14.511 12.3208 13.2674C13.5645 12.0237 14.2632 10.3369 14.2632 8.57812" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M3.8418 19H11.4207" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7.63184 15.2109V19.0004" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <p className='ml-2'>Record</p>
                    </button> */}
                    <button className="flex mx-2 font-bold px-4 rounded border items-center bg-white text-pink" onClick={() => setShowExportDialog(true)}>
                        <svg width="17" height="16" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_565_5659)">
                            <path d="M3.26367 12.75V14.25C3.26367 14.6478 3.42171 15.0294 3.70301 15.3107C3.98432 15.592 4.36585 15.75 4.76367 15.75H13.7637C14.1615 15.75 14.543 15.592 14.8243 15.3107C15.1056 15.0294 15.2637 14.6478 15.2637 14.25V12.75" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5.51367 6.75L9.26367 3L13.0137 6.75" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9.26367 3V12" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_565_5659">
                            <rect width="18" height="18" fill="white" transform="translate(0.263672)"/>
                            </clipPath>
                            </defs>
                        </svg>
                        <p className='ml-2'>Export</p>
                    </button>
                    {showExportDialog && createPortal(
                        <ExportDialog
                            handleClose={() => setShowExportDialog(false)}
                            meetings={meetings}
                            notes={notes}
                        />,
                        document.body
                    )}
                    <UserDropdown />
                </div>
            </div>
        </div>
  )
}
